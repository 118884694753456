import PropTypes from 'prop-types'
import React from 'react'

class CheckoutSummary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      summary: this.calculateSummary(props.projectItems),
      agent: props.agent,
      installationCost: props.installationCost
    }
  }

  toggleCheckbox = () => {
    this.setState({ rulesAccepted: !this.state.rulesAccepted })
  }

  calculateSummary(items) {
    let variants = flatMap(items, (i) => i.variants)
    return variants.reduce(
      (acc, variant) => acc + parseFloat(variant.discounted_total_price),
      0
    )
  }

  render() {
    const generatePojectItems = () =>
      this.props.projectItems.map((item) =>
        item.variants.map((variant) => {
          return (
            <li
              className='checkout-summary__projectItem'
              key={variant.variant_id}
            >
              <p className='checkout-summary__text'>
                {item.product_name}: {item.product_type_name}
              </p>

              <p className='checkout-summary__text checkout-summary__text--right'>
                {variant.discounted_total_price}&nbsp;{`zł`}
              </p>
            </li>
          )
        })
      )

    const generateSummary = () => {
      return (
        <div className='checkout-summary__summary'>
          <h3 className='checkout-summary__title'>{`Szacowana kwota: ${priceForHumans(
            this.state.summary
          )} zł`}</h3>

          <p className='checkout-summary__hint'>
            {this.state.agent
              ? this.state.installationCost
                ? '+ koszt montażu od ' + this.state.installationCost + ' zł'
                : '+ cena montażu ustalana indywidualnie'
              : '+ koszt montażu'}
          </p>
          <p className='checkout-summary__info'>{`Pamiętaj! Podana wartość to tylko szacunek. Teraz nic nie płacisz. Ostateczną cenę produktów poznasz po spotkaniu z naszym doradcą.`}</p>
        </div>
      )
    }

    const generateCustomerData = () => (
      <div className='checkout-summary__detail'>
        <h3 className='checkout-summary__title checkout-summary__title--spaced'>{`Dane adresowe`}</h3>

        <p className='checkout-summary__text'>
          {this.props.customerData.address}
        </p>

        <p className='checkout-summary__text'>
          {this.props.customerData.phoneNumber}
        </p>

        <p className='checkout-summary__text'>
          {this.props.customerData.email}
        </p>

        {this.props.customerData.message && (
          <p className='checkout-summary__text checkout-summary__text--bold checkout-summary__text--spaced'>{`Wiadomość:`}</p>
        )}

        {this.props.customerData.message && (
          <p className='checkout-summary__text'>
            {this.props.customerData.message}
          </p>
        )}
      </div>
    )

    const generateAgent = () => {
      if (this.state.agent) {
        return (
          <div className='checkout-summary__detail'>
            <h3 className='checkout-summary__title checkout-summary__title--spaced'>{`Twój specjalista`}</h3>

            <p className='checkout-summary__text'>{this.state.agent.name}</p>

            <p className='checkout-summary__text'>{this.state.agent.address}</p>

            <p className='checkout-summary__text'>
              {this.state.agent.phoneNumber}
            </p>

            <p className='checkout-summary__text'>{this.state.agent.email}</p>

            {this.state.agent.responseTime && (
              <p className='checkout-summary__text checkout-summary__text--bold checkout-summary__text--spaced'>{`Czas realizacji zamówienia:`}</p>
            )}

            {this.state.agent.responseTime && (
              <p className='checkout-summary__text'>{`Zadzwonimy w ciągu ${this.state.agent.responseTime}h.`}</p>
            )}
          </div>
        )
      }

      return (
        <div className='checkout-summary__detail'>
          <h3 className='checkout-summary__title checkout-summary__title--spaced'>{`Twój specjalista`}</h3>

          <p className='checkout-summary__text'>{`Nie wybrałeś/aś specjalisty. To oznacza, że zespół Modnie w Oknie znajdzie najlepszego wykonawcę dla Twojego projektu. Skontaktujemy się z Tobą w ciągu 24h.`}</p>
        </div>
      )
    }

    return (
      <section className='checkout-summary'>
        <div className='checkout-summary__project'>
          <h2 className='checkout-summary__title checkout-summary__title--spaced'>
            <svg
              width='20px'
              height='25px'
              viewBox='0 0 20 25'
              fill='#292a3d'
              className='checkout-summary__icon'
            >
              <path d='M17.9343225,6.25 L15.2165725,6.25 L15.2165725,5.76075 C15.2165725,2.582 12.6080725,0 9.5653225,0 L9.456925,0 C6.412925,0 3.804675,2.582 3.804675,5.76075 L3.804675,6.25 L1.086925,6.25 C0.489275,6.25 0,6.7666 0,7.3916 L0,23.3701005 C0,23.9677505 0.48925,24.4570255 1.086925,24.4570255 L17.934675,24.4570255 C18.532325,24.4570255 19.0216,23.9677755 19.0216,23.3701005 L19.0216,7.36435 C19.0216,7.092875 18.9132025,6.79405 18.695425,6.576275 C18.5046425,6.385745 18.2331475,6.25 17.9343225,6.25 Z M5.9783225,5.76075 C5.9783225,3.77735 7.6081975,2.17375 9.4558225,2.17375 L9.56422,2.17375 C11.412845,2.17375 13.04272,3.777275 13.04272,5.76075 L13.04272,6.25 L5.97822,6.25 L5.9783225,5.76075 Z M16.8475725,22.2832492 L2.1738225,22.2832492 L2.1738225,8.42375 L3.8046725,8.42375 L3.8046725,10.29875 C3.8046725,10.8964 4.2939225,11.385675 4.8915975,11.385675 C5.4892475,11.385675 5.9785225,10.896425 5.9785225,10.29875 L5.9785225,8.42375 L13.0440225,8.42375 L13.0440225,10.29875 C13.0440225,10.8964 13.5332725,11.385675 14.1309475,11.385675 C14.7286225,11.385675 15.2178725,10.896425 15.2178725,10.29875 L15.216895,8.42375 L16.847745,8.42375 L16.8475725,22.2832492 Z' />
            </svg>

            {`Projekt`}
          </h2>

          <ul>{generatePojectItems()}</ul>

          {generateSummary()}
        </div>

        <div className='checkout-summary__details'>
          {generateCustomerData()}

          {generateAgent()}

          <div className='checkout-summary__actions'>
            <button
              className='button button--flat'
              onClick={this.props.onBack}
            >{`wróć`}</button>

            <div className='checkout-summary__finalize'>
              <button
                className='button checkout-summary__button checkout-summary__button--right'
                onClick={() => {
                  this.props.onFinalize(true)
                }}
              >
                {`Umów się na spotkanie`}
              </button>

              <small>{`Teraz nic nie płacisz`}</small>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

CheckoutSummary.propTypes = {
  projectItems: PropTypes.array,
  customerData: PropTypes.object,
  agent: PropTypes.object,
  onFinalize: PropTypes.func,
  installationCost: PropTypes.number
}

CheckoutSummary.defaultProps = {
  projectItems: []
}

export default CheckoutSummary
