import React from 'react';
import debounce from './../helpers/debounce';

class Field extends React.Component {

  handleInputChange = debounce((value, detail) => {
    this.props.handleChange(this.props.name, value, detail);
  }, 400);

  componentDidMount() {
    if (this.refs.input && this.props.autolocation) {
      this.refs.input.addEventListener('place_changed', (event) => {
        this.handleInputChange(event.target.value, event.detail);
      }, false);
    }
  }

  render() {
    const generateLocationButtons = () => {
      return (
        <div>
          <button type='button' className='js_autocomplete-button field__location-button'/>
          <div className='js_autocomplete-button field__location-icon'/>
        </div>
      );
    };

    const generateTooltip = (text) => {
      return <span className='field__tooltip'>{text}</span>;
    };

    const generateInput = () => {
      return (
        <input
          className={`field__input ${this.props.additionalInputClassNames ? this.props.additionalInputClassNames : ''}`}
          id={this.props.id}
          placeholder={this.props.placeholder}
          type={this.props.type}
          name={this.props.name}
          onChange={(event) => this.handleInputChange(event.target.value)}
          ref='input'
          defaultValue={this.props.defaultValue}
          autoComplete='off'
        />
      );
    };

    const generateTextarea = () => {
      return (
        <textarea
          id={this.props.id}
          className='field__textarea'
          name={this.props.name}
          onKeyUp={(event) => this.handleInputChange(event.target.value)}
        />
      );
    };

    const generateSelect = () => {
      return (
          <select
            if={this.props.id}
            className='field__select'
            name={this.propr.name}
            onChange={(event) => this.handleInputChange(event.target.value)}
          >
            this.props.source.forEach(s => {
              <option
                value={s.id}
              >
                {t.name}
              </option>
            });
          </select>
      );
    };

    return (
      <div className={`field field--form ${this.props.error ? 'field--has-error' : ''} ${this.props.additionalClassNames ? this.props.additionalClassNames : ''}`}>
        {this.props.label ? <label className='field__label' htmlFor={this.props.id}>{this.props.label}</label> : null}

        {this.props.type ? generateInput() : generateTextarea()}

        {this.props.autolocation ? generateLocationButtons() : null}

        {(this.props.tooltip || this.props.error) ? generateTooltip(this.props.error ? this.props.error : this.props.tooltip) : null}
      </div>
    );
  }
}

export default Field;
