import PropTypes from 'prop-types'
import React from 'react'
import Picker from './Picker'
import ScrollbarsHorizontal from './ScrollbarsHorizontal'

class ProductPreview extends React.Component {
  references = {
    overlays: [],
    container: null,
    background: null
  }

  blockingImageKeys = ['background', 'frame', 'material', 'mechanism']

  constructor(props) {
    super(props)
    this.state = {
      scale: 465 / 500,
      isLoading: true,
      zoomActive: false
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.blockingImageKeys.some(
        (key) =>
          (prevProps.selection[key] && prevProps.selection[key].path) !==
          (this.props.selection[key] && this.props.selection[key].path)
      )
    ) {
      this.setState({ isLoading: true })
    }
  }

  componentDidMount() {
    this.attachImagesOnload()
    this.rescale()
    window.addEventListener('resize', this.rescale.bind(this))
  }

  attachImagesOnload() {
    const refs = this.references
    const allOverlaysComplete = () =>
      Object.keys(refs.overlays).every((key) => refs.overlays[key].complete) &&
      refs.background.complete
    const onAllReady = () => {
      if (refs.background.complete && allOverlaysComplete()) {
        this.setState({ isLoading: false }, this.props.onImagesReady)
      }
    }

    Object.keys(refs.overlays).forEach((key) => {
      refs.overlays[key].onload = () => {
        onAllReady()
      }
    })

    refs.background.onload = () => {
      onAllReady()
      this.rescale()
    }
  }

  rescale() {
    const refs = this.references

    if (refs.background.complete) {
      const scale =
        refs.container.getBoundingClientRect().height /
        refs.background.naturalHeight

      if (scale !== this.state.scale) {
        this.setState({ scale })
      }
    }
  }

  render() {
    const { selection } = this.props

    const generateOverlay = (property) => (
      <img
        ref={(n) => (this.references.overlays[property] = n)}
        className='product-preview__overlay'
        src={selection[property] ? selection[property].path : ''}
        alt={selection[property] ? selection[property].name : ''}
      />
    )

    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }

    return (
      <div
        className={`product-preview ${this.props.addedClassNames || ''}`}
        ref={(n) => (this.references.container = n)}
      >
        <div className='product-preview__wrapper'>
          <img
            ref={(n) => (this.references.background = n)}
            className='product-preview__background'
            src={selection.background ? selection.background.path : ''}
            alt={selection.background ? selection.background.name : ''}
            style={{
              transform: `translate(-50%, -50%) scale(${this.state.scale})`
            }}
          />

          <div
            className='product-preview__overlays'
            style={{
              top: `${this.props.overlayPosition.x}%`,
              left: `${this.props.overlayPosition.y}%`,
              transform: `translate(-50%, -50%) scale(${this.state.scale})`
            }}
          >
            {generateOverlay('frame')}
            {generateOverlay('material')}
            {generateOverlay('mechanism')}
          </div>
        </div>

        <button
          type='button'
          className={`product-preview__arrow ${this.props.hidePicker}`}
          onClick={() => {
            this.props.onBgNavClick(true, null)
          }}
        />

        <button
          type='button'
          className={`product-preview__arrow ${this.props.hidePicker} product-preview__arrow--next`}
          onClick={() => {
            this.props.onBgNavClick()
          }}
        />

        <Picker
          items={this.props.frames}
          onItemClick={this.props.onPickerItemClick}
          additionalClasses='product-preview__action product-preview__action--right'
          iconClasses='product-preview__action-image icon icon--window'
        />

        {this.props.promotion && this.props.percentage_discount_sum ? (
          <div className='button products__promotion-tag'>
            {'-' + this.props.percentage_discount_sum + ' %'}
          </div>
        ) : (
          ''
        )}

        {this.state.zoomActive && (
          <div className='product-preview__wrapper'>
            <img
              className={`product-preview__zoom${
                this.state.zoomActive ? ' product-preview__zoom--active' : ''
              }`}
              loading='lazy'
              src={selection.material ? selection.material.zoom : ''}
              alt={selection.material ? selection.material.name : ''}
            />
          </div>
        )}

        <button
          type='button'
          className='product-preview__action'
          onClick={() => {
            this.setState({ zoomActive: !this.state.zoomActive })
          }}
        >
          <i
            className={`product-preview__action-image icon icon--zoom${
              this.state.zoomActive ? ' icon--zoom-minus' : ''
            }`}
          />
        </button>

        <div
          className={`loader loader--delayed${
            this.state.isLoading ? ' loader--active' : ''
          }`}
        />
      </div>
    )
  }
}

ProductPreview.propTypes = {
  addedClassNames: PropTypes.string,
  selection: PropTypes.object,
  frames: PropTypes.array,
  backgrounds: PropTypes.array,
  overlayPosition: PropTypes.object,
  onPickerItemClick: PropTypes.func,
  onBgNavClick: PropTypes.func
}

export default ProductPreview
