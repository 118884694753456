//= require shared/helpers/findInArray
//= require shared/helpers/flatMap

import React from 'react'
import PropTypes from 'prop-types'

import ProductOverview from './ProductOverview'
import Button from './Button'

import debounce from './../helpers/debounce'
import { CSSTransitionGroup } from 'react-transition-group'

class CheckoutCart extends React.Component {
  removalTimeout = null

  constructor(props) {
    super(props)

    this.state = this.newState(props)
  }

  toggleCheckbox = () => {
    this.setState({ rulesAccepted: !this.state.rulesAccepted })
  }

  newState = (props) => ({
    deletedVariantIds: [],
    projectItems: props.projectItems,
    summary: this.calculateSummary(props.projectItems),
    installationCost: props.installationCost,
    agent: props.initialAgent,
    rulesAccepted: false,
  })

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    console.log(this.state)
    this.setState(this.newState(nextProps))
  }

  setInstallationCost = (agent, project_items) => {
    let installationCost = 0

    project_items.forEach((i) => {
      i.variants.forEach((v) => {
        installationCost += this.getInstallationCost(i, agent) * v.quantity
      })
    })

    this.setState({
      installationCost: installationCost ? parseFloat(installationCost) : null,
    })
  }

  getInstallationCost = (product, agent) => {
    let product_id = product ? product.product_id : null
    let agent_id = agent ? agent.id : null
    var installationCost = 0

    $.ajax({
      url:
        '/api/project_product?product_id=' +
        product_id +
        '&agent_id=' +
        agent_id,
      type: 'get',
      dataType: 'json',
      processData: false,
      async: false,
    }).done(function (data) {
      installationCost += data
    })

    return installationCost
  }

  calculateSummary(projectItems, ommitedVariantId) {
    const variants = flatMap(projectItems, (i) => i.variants)
    const ommittedVariantIndex = ommitedVariantId
      ? variants.findIndex((v) => v.id === ommitedVariantId)
      : -1

    if (ommittedVariantIndex > -1) {
      variants.splice(ommittedVariantIndex, 1)
    }

    return variants.reduce(
      (acc, variant) => acc + parseFloat(variant.discounted_total_price),
      0
    )
  }

  callVariantUpdate = debounce((id, quantity) => {
    $.ajax({
      type: 'PATCH',
      url: `/api/project_items/${id}`,
      data: { quantity },
      success: () => {
        window.dispatchEvent(new CustomEvent('cartShouldUpdate'))
      },
    })
  }, 500)

  callVariantRemove = (id) => {
    $.ajax({
      type: 'DELETE',
      url: `/api/project_items/${id}`,
      success: () => {
        window.dispatchEvent(new CustomEvent('cartShouldUpdate'))
      },
    })
  }

  updateVariant = (value, id, projectItemId) => {
    const newProjectItems = [...this.state.projectItems]
    const projectItem = findInArray(newProjectItems, projectItemId, 'item_id')
    const variant = findInArray(projectItem.variants, id, 'id')

    variant.quantity = value

    this.setState({
      projectItems: newProjectItems,
      summary: this.calculateSummary(newProjectItems),
    })

    this.callVariantUpdate(id, value)

    this.props.setInstallationCost
      ? this.props.setInstallationCost(
          this.state.agent,
          this.state.projectItems
        )
      : this.setInstallationCost(this.state.agent, this.state.projectItems)
  }

  removeVariant = (id, projectItemId) => {
    this.setState({
      summary: this.calculateSummary(this.state.projectItems, id),
      deletedVariantIds: [...this.state.deletedVariantIds, id],
    })

    this.removalTimeout = setTimeout(() => {
      let newProjectItems = [...this.state.projectItems]
      const projectItem = findInArray(newProjectItems, projectItemId, 'item_id')

      projectItem.variants = projectItem.variants.filter((v) => v.id !== id)

      if (projectItem.variants.length <= 0) {
        newProjectItems = newProjectItems.filter(
          (i) => i.item_id !== projectItemId
        )
      }

      this.setState({
        projectItems: newProjectItems,
        summary: this.calculateSummary(newProjectItems),
      })

      this.props.setInstallationCost
        ? this.props.setInstallationCost(
            this.state.agent,
            this.state.projectItems
          )
        : this.setInstallationCost(this.state.agent, this.state.projectItems)

      this.callVariantRemove(id)
    }, 3000)
  }

  restoreVariant = () => {
    clearTimeout(this.removalTimeout)

    this.setState({
      summary: this.calculateSummary(this.state.projectItems),
    })
  }

  isCartEmpty = () => this.state.projectItems.length <= 0

  finalize = () => {
    const items = [...this.state.projectItems].reduce((acc, item) => {
      const variants = item.variants.filter(
        (variant) =>
          this.state.deletedVariantIds.indexOf(variant.variant_id) < 0
      )
      if (variants.length) {
        acc.push({ ...item, variants })
      }
      return acc
    }, [])

    this.props.onFinalize(items)
  }

  generateHeader = () => {
    const headerProperties = [
      { label: 'first', properties: ['product'] },
      { label: 'second', properties: ['description'] },
      {
        label: 'third',
        properties: ['count', 'dimensions', 'price', 'delete'],
      },
    ]

    const cartHeader = {
      product: 'Produkt',
      description: 'Opis',
      count: 'Sztuki',
      dimensions: 'Wymiary',
      price: 'Kwota',
      delete: 'Usuń',
    }

    return (
      <div className='checkout-cart__header'>
        {headerProperties.map((p) => {
          return (
            <div
              className={`checkout-cart__group checkout-cart__group--${p.label}`}
              key={p.label}
            >
              {p.properties.map((pr) => (
                <span className='checkout-cart__header-item' key={pr}>
                  {cartHeader[pr]}
                </span>
              ))}
            </div>
          )
        })}
      </div>
    )
  }

  generateProjectItems = (projectItems) => {
    const transitionProperties = {
      transitionName: 'disappear',
      transitionEnterTimeout: 600,
      transitionLeaveTimeout: 600,
      component: 'ul',
      className: 'checkout-cart__products',
    }

    return (
      <CSSTransitionGroup {...transitionProperties}>
        {projectItems.map((item) => {
          return (
            <li className='checkout-cart__product-overview' key={item.item_id}>
              <ProductOverview
                {...item}
                updateVariant={this.updateVariant}
                removeVariant={this.removeVariant}
                restoreVariant={this.restoreVariant}
              />
            </li>
          )
        })}
      </CSSTransitionGroup>
    )
  }

  generateSummary = (summary) => (
    <div className='checkout-cart__summary'>
      <p className='checkout-cart__hint'>
        {`Podana kwota/wartość ma wyłącznie cel informacyjny i nie stanowi oferty w rozumieniu Kodeksu Cywilnego. Po wykonaniu dokładnych pomiarów okien przez Sprzedawcę będzie możliwe określenie wyceny i przedstawienie oferty.`}
        <br />
        <span>{`Wycena nie zobowiązuje cię do żadnych płatności. Przekazane informacje pomogą nam lepiej przygotować się do kontaktu z tobą.`}</span>
      </p>

      <p className='checkout-cart__price'>
        {`Szacowana kwota:`} {summary}
        {`zł`}
        <span className='checkout-cart__price-detail'>
          {this.state.agent
            ? this.state.installationCost
              ? '+ koszt montażu od %{price} ' +
                this.state.installationCost +
                ' zł'
              : '+ cena montażu ustalana indywidualnie'
            : '+ koszt montażu'}
        </span>
      </p>
    </div>
  )

  generateEmptyState = () => (
    <div className='checkout-cart__empty-cart'>
      <h2 className='checkout-cart__empty-cart-title'>{`Usunięto wszystkie produkty`}</h2>

      <p className='checkout-cart__empty-cart-text'>{`Przejdź do strony głównej i przejrzyj ponownie katalog naszych produktów.`}</p>

      <a
        href='/'
        className='button button--bordered'
      >{`wróć do strony głównej`}</a>
    </div>
  )

  generateActions = () => {
    return (
      <div className='checkout-cart__actions'>
        <div className='checkout-cart__actions-group checkout-cart__actions-group--primary'>
          <Button
            text={`przejdź do danych kontaktowych`}
            classNames='button'
            disabled={this.isCartEmpty()}
            onClick={this.finalize}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <section
        className={`checkout-cart${
          this.props.contained ? ' checkout-cart--contained' : ''
        }`}
      >
        {this.isCartEmpty() ? null : this.generateHeader()}

        {this.isCartEmpty()
          ? this.generateEmptyState()
          : this.generateProjectItems(this.state.projectItems)}

        {this.isCartEmpty() ? null : (
          <div className='checkout-cart__footer'>
            {this.generateSummary(priceForHumans(this.state.summary))}

            {this.generateActions()}
          </div>
        )}
      </section>
    )
  }
}

CheckoutCart.propTypes = {
  projectItems: PropTypes.array,
  contained: PropTypes.bool,
  onFinalize: PropTypes.func,
  installationCost: PropTypes.number,
  setInstallationCost: PropTypes.func,
  initialAgent: PropTypes.object,
}

CheckoutCart.defaultProps = {
  projectItems: [],
  contained: false,
  installationCost: null,
}

export default CheckoutCart
