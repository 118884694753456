import React, { FC, JSX, useEffect } from 'react'

interface IModal {
  renderContent: () => JSX.Element
  overlayTransparent?: boolean
  isOpen: boolean
  onCloseClick: () => void
}

const Modal: FC<IModal> = ({
  renderContent,
  overlayTransparent,
  isOpen,
  onCloseClick
}): JSX.Element => {
  useEffect(() => {
    const action = isOpen ? 'add' : 'remove'
    document.body.classList[action]('body--no-scroll')
    return () => {
      document.body.classList.remove('body--no-scroll')
    }
  }, [isOpen])

  return (
    <div className={`modal${isOpen ? ' modal--open' : ''}`}>
      <div
        className={`modal__overlay${
          overlayTransparent ? ' modal__overlay--transparent' : ''
        }`}
      />
      <div className='modal__window'>
        <header className='modal__header'>
          <button className='close' onClick={onCloseClick} />
        </header>

        <section className='modal__content'>{renderContent()}</section>
      </div>
    </div>
  )
}

export default Modal
