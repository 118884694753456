/* global document, fetch, clearTimeout, setTimeout */
import React from 'react'
import PropTypes from 'prop-types'
import Stepper from './Stepper'

import debounce from './../helpers/debounce'

class ProductFilter extends React.Component {
  static properties_name = {
    width: 'Szerokość okna (cm):',
    height: 'Wysokość okna (cm):',
    colors: 'Wybierz kolor:'
  }

  constructor(props) {
    super(props)
    this.state = {
      count: this.props.count,
      description: this.props.description,
      isOpen: false,
      contentHeight: 0,
      selection: {
        transparencies: this.props.selectedTransparencies,
        width: this.props.dimensions.width.selected,
        height: this.props.dimensions.height.selected,
        category_id: this.props.category ? this.props.category.id : null,
        type_id: this.props.type ? this.props.type.id : null
      }
    }
  }

  componentDidMount() {
    this.updateContentSize()

    window.addEventListener(
      'resize',
      debounce(() => {
        this.updateContentSize()
      })
    )
  }

  updateContentSize = () => {
    this.setState({
      contentHeight: this.refs.content ? this.refs.content.clientHeight : 0
    })
  }

  changeDimension(dimension, val) {
    this.setState(
      { selection: { ...this.state.selection, [dimension]: val } },
      () => {
        this.count()
      }
    )
  }

  toggleTransparency(id) {
    let selectedTransparencies = this.state.selection.transparencies
    const transparency_index = this.transparencyIndex(id)

    if (transparency_index > -1) {
      selectedTransparencies.splice(transparency_index, 1)
    } else {
      selectedTransparencies.push(id)
    }

    this.setState(
      {
        selection: {
          ...this.state.selection,
          transparencies: selectedTransparencies
        }
      },
      () => {
        this.count()
      }
    )
  }

  transparencyIndex = (id) => this.state.selection.transparencies.indexOf(id)

  isTransparencySelected = (id) => this.transparencyIndex(id) > -1

  generateProductStepper = (property, additionalClass = '') => {
    const dimensionSetup = this.props.dimensions[property]
    const properties_name = {
      width: 'Szerokość okna (cm):',
      height: 'Wysokość okna (cm):',
      colors: 'Wybierz kolor:'
    }

    return (
      <Stepper
        additionalClassNames={`${additionalClass}`}
        id={`product_filter_${property}`}
        label={properties_name[property]}
        help={
          property === 'width'
            ? {
                label: 'Jak mierzyć okno?',
                callback: () => {
                  console.log('show measuring popup')
                }
              }
            : null
        }
        val={this.state.selection[property]}
        min={dimensionSetup.min}
        max={dimensionSetup.max}
        step={dimensionSetup.step}
        initialValue={dimensionSetup.value}
        onValueUpdate={(val) => this.changeDimension(property, val)}
      />
    )
  }

  query() {
    return (
      '?' +
      ['category_id', 'type_id', 'colors', 'transparencies', 'width', 'height']
        .filter((key) => this.state.selection[key])
        .map((key) => {
          const val = this.state.selection[key]
          if (val instanceof Array) {
            return val.map((id) => key + '[]=' + id).join('&')
          } else {
            return key + '=' + val
          }
        })
        .join('&')
    )
  }

  count() {
    if (this.countTimeout) {
      clearTimeout(this.countTimeout)
    }

    this.countTimeout = setTimeout(() => {
      this.countTimeout = null

      fetch(this.props.countUrl + this.query(), {
        headers: { Accept: 'application/json' },
        credentials: 'same-origin'
      })
        .then((response) => response.json())
        .then((json) => {
          this.setState({ count: json.count })
        })
    }, 200)
  }

  search = () => {
    document.location.href = document.location.href.split('?')[0] + this.query()
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  hasConstrains = () => {
    const s = this.state.selection
    return (
      s.height ||
      s.width ||
      (s.transparencies && s.transparencies.length) ||
      (s.colors && s.colors.length)
    )
  }

  resetConstrains = () => {
    this.setState(
      {
        selection: {
          ...this.state.selection,
          width: null,
          height: null,
          colors: [],
          transparencies: []
        }
      },
      this.search
    )
  }

  render() {
    const transparency_level_description = {
      blank: 'Bez transparentności',
      transparent: 'Transparentny',
      darkening: 'Półprzezroczysty',
      blackout: 'Zaciemniający'
    }

    const breadcrumbs = () => {
      if (this.props.type && this.props.category) {
        return (
          <ul className='flex-list breadcrumbs'>
            <li>
              <a className='breadcrumbs__item' href={this.props.category.url}>
                {this.props.category.name}
              </a>
              &middot;
              <a
                className='breadcrumbs__item breadcrumbs__item--last'
                href={this.props.type.url}
              >
                {this.props.type.name}
              </a>
            </li>
          </ul>
        )
      }
      return null
    }

    const header = () => {
      const title = () =>
        this.props.type
          ? this.props.type.name
          : this.props.category
          ? this.props.category.name
          : 'Produkty'
      const resetButton = () => (
        <button
          onClick={this.resetConstrains}
          className='button button--flat hide-below-md'
        >
          Wyczyść filtry
        </button>
      )

      return (
        <div className='filter__header'>
          {breadcrumbs()}

          <div className='filter__title'>
            <h1>{title()}</h1>
            <div
              className='filter__description depiction__text'
              dangerouslySetInnerHTML={{ __html: this.props.description }}
            ></div>
            {this.hasConstrains() ? resetButton() : null}
          </div>

          {/*<button className='button filter__toggler' onClick={this.toggle}>*/}
          {/*  Filtruj*/}
          {/*</button>*/}
        </div>
      )
    }

    const transparencies = () => (
      <ul className='filter__properties'>
        {this.props.transparencies.map(([name, id]) => (
          <li
            key={`transparency-${id}`}
            className='filter__property field field--checkbox field--regular'
          >
            <input
              type='checkbox'
              id={`transparency-${id}`}
              className='field__input'
              value={id}
              defaultChecked={this.isTransparencySelected(id)}
              onChange={() => {
                this.toggleTransparency(id)
              }}
            />

            <label htmlFor={`transparency-${id}`} className='field__label'>
              {transparency_level_description[name]}
            </label>
          </li>
        ))}
      </ul>
    )

    const renderTransparencies = () => {
      if (!this.props.category.without_transparencies) {
        return transparencies()
      }
    }

    const buttonText = () => {
      if (this.state.count == 0) {
        return `Brak produktów`
      } else if (this.state.count == 1) {
        return `Pokaż jeden produkt`
      } else if (this.state.count < 5) {
        return `Pokaż ${this.state.count} produkty`
      } else {
        return `Pokaż ${this.state.count} produktów`
      }
    }

    return (
      <section className={`filter${this.state.isOpen ? ' filter--open' : ''}`}>
        {header()}

        <section
          className='filter__body'
          style={{
            height: `${
              this.state.isOpen && this.state.contentHeight
                ? this.state.contentHeight
                : 0
            }px`
          }}
        >
          <div className='filter__content' ref='content'>
            <div className='filter__content-level'>
              <div className='filter__content-part'>
                {renderTransparencies()}
              </div>

              <div className='filter__content-part'>
                {this.generateProductStepper('width', 'field--grand')}
                {this.generateProductStepper('height', 'field--grand')}
              </div>
            </div>

            <div className='filter__content-level'>
              <div className='filter__content-part'></div>

              <div className='filter__actions'>
                <button
                  onClick={this.resetConstrains}
                  className='button button--flat hide-md'
                >
                  Wyczyść filtry
                </button>

                <button
                  className='filter__submit button'
                  disabled={this.state.count < 1}
                  onClick={this.search}
                >
                  {buttonText()}
                </button>
              </div>
            </div>
          </div>
        </section>
      </section>
    )
  }
}

ProductFilter.propTypes = {
  count: PropTypes.number,
  description: PropTypes.string,
  countUrl: PropTypes.string,
  category: PropTypes.object,
  type: PropTypes.object,
  dimensions: PropTypes.object,
  selectedColors: PropTypes.arrayOf(PropTypes.number),
  transparencies: PropTypes.arrayOf(PropTypes.array),
  selectedTransparencies: PropTypes.arrayOf(PropTypes.number)
}

export default ProductFilter
