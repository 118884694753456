import React from 'react';
import PropTypes from 'prop-types';

import agentDataMapper from './../helpers/mappers/agentDataMapper';
import addressGeocoder from './../helpers/addressGeocoder';
import coordinatesDistance from './../helpers/coordinatesDistance';

import Agent from './Agent';
import Button from './Button';
import Field from './Field';

import { CSSTransitionGroup } from 'react-transition-group';

class CheckoutAgent extends React.Component {
  constructor(props) {
    super(props);

    this.agentsLimit = 6;
    this.state = {
      isOpen: props.initiallyOpen,
      agents: this.props.initialAgent ? [this.props.initialAgent] : [],
      ...this.props.initialLocation,
      projectItems: props.projectItems,
    };
  }

  componentDidMount() {
    const { coordinates } = this.state;

    if(coordinates.lat && coordinates.lng) {
      this.refreshAgentsData(coordinates);
    }

    if(window.mapsReady) {
      this.onMapsReady();
    } else {
      window.addEventListener('mapsReady', this.onMapsReady);
    }
  }

  onMapsReady = () => {
    initializeLocation();

    if (!(this.state.coordinates.lat && this.state.coordinates.lng)) {
      addressGeocoder(this.state.address, (coordinates) => {
        this.setState({ coordinates }, () => {this.refreshAgentsData(coordinates);});
      });
    }
  };

  refreshAgentsData = ({ lat, lng }) => {
    const params = `lat=${lat};lng=${lng};limit=${this.agentsLimit}`;

    fetch(`/api/agents?${params}`, { headers: { Accept: 'application/json' }, credentials: 'same-origin' })
      .then(r => r.json())
      .then(s => {
        const agents = s.map(agentDataMapper);
        const initial = this.props.initialAgent;

        if(initial) {
          initial.distance = coordinatesDistance(initial.lat, initial.lng, lat, lng).toFixed(2);
        }

        this.setState({
          agents: (initial ? [initial, ...agents.filter(agent => agent.id !== initial.id)] : agents)
        });
      });
  };

  showMoreAgents = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  skipStep = () => {
    this.setState({ selectedAgentId: null }, this.finalize);
  };

  onInputValueChange = (name, value, coordinates) => {
    this.setState({
      address: value,
      coordinates
    });

    this.refreshAgentsData(coordinates);
  };

  finalize = (clear) => {
    this.props.onFinalize(clear ? null : this.state.agents.find(agent => agent.id === this.props.selectedAgentId));
  };

  isAgentSelected = id => id && (this.props.selectedAgentId === id);

  onAgentClick = id => {
    this.props.updateAgent(id ? this.state.agents.find(agent => agent.id === id) : null);
    /*this.props.setInstallationCost(id ? this.state.agents.find(agent => agent.id === id) : null, this.state.projectItems);*/
  };

  renderActions = () => (
    <div className='checkout-agent__actions'>
      <Button classNames='button button--flat checkout-agent__button--left' onClick={this.props.onBack} text='wróć'/>

      <small className='checkout-agent__hint'>Sprzedawca skontaktuje się z Tobą bezpośrednio po wysłaniu przez Ciebie projektu.</small>

      <Button classNames='button' text='przejdź do podsumowania' onClick={() => {this.finalize(false);}}/>
    </div>
  );

  generateAddressField = () => (
    <Field
      placeholder='Ulica, miasto, kod pocztowy'
      type='text'
      name='address'
      label='Adres montażu:'
      id='checkout-customer-address'
      autolocation='true'
      additionalClassNames='field--location js_autocomplete-address checkout-agent__field'
      additionalInputClassNames='js_autocomplete-input'
      handleChange={this.onInputValueChange}
      defaultValue={this.state.address}
    />
  );

  generateHeader = () => (
    <div className='checkout-agent__header'>
      {this.generateAddressField()}

      <button className='button button-ebony hidden-mobile' onClick={() => {this.finalize(true);}}>
        Nie chcę wybierać sprzedawcy
      </button>
      <button className='button button-ebony hidden-desktop' onClick={() => {this.finalize(true);}}>
        Pomiń
      </button>

      <p className='checkout-agent__text'>{this.props.selectedAgentId ? 'Twój wybrany sprzedawca to:' : 'Sprzedawcy najbliżej Ciebie to:'}</p>
    </div>
  );

  render() {
    const transitionProperties = {
      transitionName: 'fade',
      transitionEnterTimeout: 600,
      transitionLeaveTimeout: 600,
      component: 'ul',
      className: 'checkout-agent__agents'
    };

    const generateShowMoreButton = () => {
      return (
        <Button classNames='checkout-agent__expand' text='pokaż więcej sprzedawców' onClick={this.showMoreAgents}/>
      );
    };

    const generateAgents = (agents) => {
      if (!this.state.agents.length) {
        return (<p>Brak sprzedawców do wyświetlenia</p>);
      }

      if (this.state.isOpen) {
        return agents.map(agent => (
          <Agent
            key={agent.id}
            {...agent}
            onAgentClick={this.onAgentClick}
            isSelected={this.isAgentSelected(agent.id)}
            isOpen={this.state.isOpen}
            starIcon={this.props.starIcon}
          />
        ));
      }

      return (
        <Agent
          {...agents[0]}
          onAgentClick={this.onAgentClick}
          isSelected={this.isAgentSelected(agents[0].id)}
          starIcon={this.props.starIcon}
        />
      );
    };

    return (
      <section className='checkout-agent'>
        {this.generateHeader()}

        <CSSTransitionGroup {...transitionProperties}>
          {generateAgents(this.state.agents)}
        </CSSTransitionGroup>

        {(this.state.isOpen || this.state.agents.length <= 1) ? null : generateShowMoreButton()}

        {this.renderActions()}
      </section>
    );
  }
}

CheckoutAgent.propTypes = {
  initialLocation: PropTypes.object,
  selectedAgentId: PropTypes.number,
  updateAgent: PropTypes.func,
  onBack: PropTypes.func,
  onFinalize: PropTypes.func,
  initialAgent: PropTypes.object,
  initiallyOpen: PropTypes.bool,
  projectItems: PropTypes.array,
  setInstallationCost: PropTypes.func,
  starIcon: PropTypes.string
};

CheckoutAgent.defaultProps = {
  initialLocation: { address: '', coordinates: { lat: '', lng: '' } },
  initiallyOpen: false,
  onBack: () => {},
  onFinalize: () => {},
};

export default CheckoutAgent;
