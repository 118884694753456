function setCookie(name, value, days) {
  let date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

function getCookie(name) {
  const result = document.cookie.match(new RegExp(name + '=([^;]+)'));
  return result ? JSON.parse(decodeURIComponent(result[1].replace(/\+/g, "%20"))) : result;
}

export { setCookie, getCookie };
