import React from 'react';
import PropTypes from 'prop-types';

class Picker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };

    this.eventHandlers = {
      windowClick: this.handleWindowClick.bind(this)
    };

    this.optionClass = 'js_picker-option';

    this.references = { container: null };
  }

  toggleMenu(open = false) {
    this.setState({ isOpen: open });
    window[`${open ? 'add' : 'remove'}EventListener`]('click', this.eventHandlers.windowClick);
  }

  handleWindowClick(e) {
    if (parentWithClass(e.target, this.optionClass) || !this.references.container.contains(e.target)) {
      this.toggleMenu(false);
    }
  }

  render() {
    const generatePickerTiles = items => items.map((item, key) => (
      <button
        type="button"
        className={`picker__option sample sample--small ${this.optionClass}`}
        key={key}
        onClick={() => {this.props.onItemClick('frame', item.id);}}
      >
        <img className="sample__content picker__option-image" src={item.sample} alt={item.name} />
      </button>
    ));

    return (
      <div
        ref={n => (this.references.container = n)}
        className={`picker ${this.props.additionalClasses}${this.state.isOpen ? ' picker--active' : ''}`}
        onClick={() => {this.toggleMenu(!this.state.isOpen);}}
      >
        <i className={this.props.iconClasses}/>

        <div className="picker__menu">
          {generatePickerTiles(this.props.items)}
        </div>
      </div>
    );
  }
}

Picker.propTypes = {
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  additionalClasses: PropTypes.string,
  iconClasses: PropTypes.string
};

export default Picker;
