import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { setCookie, getCookie } from './../helpers/cookieService';

class Agent extends React.Component {
  locationCookieSetup = {
    name: 'userLocation',
    days: 30,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      contentHeight: 0
    };
  }

  componentDidMount() {
    this.refs.agent.addEventListener('transitionend', (event) => {
      if (event.target === this.refs.agent && this.state.isOpen) {
        this.setState({ contentHeight: this.refs.content && this.state.isOpen ? this.refs.content.clientHeight : 0 });
      }
    }, true);
  }

  toggleIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  renderHeader = () => {
    if (getCookie(this.locationCookieSetup.name)) {
      return (
        <div className='agent__header'>
          <h3 className='agent__title agent__title--left'>{this.props.name}
            {this.props.reviewSummary ? <span className="review"><img src={this.props.starIcon} alt="star" /> {this.props.reviewSummary}</span> : ''}
          </h3>
          <span className='agent__title agent__title--right'>{this.props.distance ? `${this.props.distance} km` : ''}</span>
        </div>
      );
    } else {
      return (
        <div className='agent__header'>
          <h3 className='agent__title agent__title--left'>{this.props.name}
            {this.props.reviewSummary ? <span className="review"><img src={this.props.starIcon} alt="star" /> {this.props.reviewSummary}</span> : ''}
          </h3>
        </div>
      );
    }
  }

  renderDescription = () => {
    return (
      <div className='agent__row'>
        <section className='agent__description'>
          <p className='agent__text'>{this.props.address}</p>

          <p className='agent__text'>{this.props.address2}</p>
          <a className='agent__text' href={'tel:' + this.props.phoneNumber}>
            {this.props.phoneNumber}
          </a>
        </section>

        <section className='agent__description agent__description--right'>
          {this.props.priceRangeFrom && this.props.priceRangeFrom ? <span className='agent__text'>montaż od {this.props.priceRangeFrom} zł</span> : ''}

          {(this.props.contactHours) && <p className="agent__text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.contactHours)}}></p>}
        </section>
      </div>
    );
  }

  renderCollapsibleContent = () => {
    return (
      <div
        className='agent__container'
        style={{ height: `${this.state.isOpen && this.refs.content ? this.refs.content.clientHeight : 0}px`}}
      >
        <div className='agent__row agent__row--collapsible' ref='content'>
          {this.props.description && <div className='agent__details agent__details--left'>
            <h4 className='agent__subtitle'>O nas</h4>

            <p className="agent__text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.props.description)}}></p>
          </div>}

          {this.props.responseTime && <div className='agent__details agent__details--right'>
            <h4 className='agent__subtitle'>Czas realizacji projektów</h4>

            <p className='agent__text'>
              <span>Realizujemy zapytania w ciągu {this.props.responseTime}h od otrzymania wiadomości</span>
            </p>
          </div>}
        </div>
      </div>
    );
  }

  renderActions = () => {
    const isExpandable = !!this.props.description;

    if (this.props.href) {
      return (
        <div className='agent__actions'>
          <a href={this.props.href} className='button'>Zobacz wizytówkę</a>
        </div>
      );
    }

    return (
      <div className='agent__actions'>
        <button
          onClick={() => {this.props.onAgentClick(this.props.isSelected ? null : this.props.id);}}
          className={`button ${this.props.isSelected ? '' : 'button--bordered'}`}
        >
          {this.props.isSelected ? 'wybrano' : 'wybierz'}
        </button>

        {isExpandable && <button onClick={this.toggleIsOpen} className='button button--flat agent__button agent__button--right'>
          {this.state.isOpen ? 'pokaż mniej' : 'pokaż więcej'}
        </button>}
      </div>
    );
  }

  render() {
    return (
      <li
        className={`agent ${this.props.isSelected ? 'agent--selected' : ''}${this.props.isOpen ? ' agent--small' : ''}`}
        ref='agent'
      >
        {this.renderHeader()}

        {this.renderDescription()}

        {this.renderCollapsibleContent()}

        {this.renderActions()}
      </li>
    );
  }
}

Agent.propTypes = {
  isSelected: PropTypes.bool,
  isOpen: PropTypes.bool,
  markerIcon: PropTypes.string,
  markerIconSpecial: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  phoneNumber: PropTypes.string,
  priceRangeFrom: PropTypes.number,
  contactHours: PropTypes.string,
  description: PropTypes.string,
  reviewSummary: PropTypes.string,
  starIcon: PropTypes.string
};

export default Agent;
