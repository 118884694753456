import React from 'react';

const Button = props =>
  props.href ? <a {...props} /> : <button {...props} />;

export default ({ text, href, classNames, disabled, onClick }) => {
  return (
    <Button href={href} className={classNames} disabled={disabled} onClick={onClick}>
      {text}
    </Button>
  );
};
