import PropTypes from 'prop-types'
import React from 'react'

import Modal from './Modal'
import Notification from './Notification'
import ProductAgent from './ProductAgent'
import ProductConfigurator from './ProductConfigurator'
import ProductPreview from './ProductPreview'
import ScrollbarsHorizontal from './ScrollbarsHorizontal'
import Stepper from './Stepper'

import agentDataMapper from './../helpers/mappers/agentDataMapper'

const defaultDimension = { default: 1, min: 1, max: 100, step: 1 }
const variantPriceModifiers = [
  'group',
  'material',
  'mechanism',
  'width',
  'height'
]

const timer = {
  value: null
}

const initialData = {
  product: null,
  overlayPosition: { x: 50, y: 50 },
  groups: [],
  materials: [],
  mechanisms: [],
  frames: [],
  backgrounds: [],
  dimensions: {
    width: defaultDimension,
    height: defaultDimension,
    quantity: defaultDimension
  },
  unitPrice: 0,
  assignedAgent: null
}

const initialSelection = {
  group: null,
  material: null,
  mechanism: null,
  frame: null,
  background: null,
  width: 100,
  height: 100,
  quantity: 1
}

class ProductDetail extends React.Component {
  stages = { group: 'GROUP', material: 'MATERIAL', mechanism: 'MECHANISM' }

  constructor(props) {
    super(props)
    this.state = {
      productType: this.props.productType,
      displayedName: this.props.displayedName,
      data: initialData,
      selection: initialSelection,
      stage: this.stages.group,
      isLoading: true,
      isOpen: false,
      isVariantInvalid: false,
      agent: null,
      isAgentOpen: false,
      installationCost: null,
      isNotificationOpen: false
    }
  }

  componentDidMount() {
    const getProductData = fetch(`${this.props.productPath}.json`, {
      headers: { Accept: 'application/json' },
      credentials: 'same-origin'
    }).then((r) => r.json())
    const getProjectData = fetch('/api/project', {
      headers: { Accept: 'application/json' },
      credentials: 'same-origin'
    }).then((r) => r.json())
    Promise.all([getProductData, getProjectData]).then((values) => {
      this.onDataReady(values[0], values[1].agent)
    })
  }

  onDataReady = (productData, currentAgentData) => {
    this.setState({
      data: {
        ...productData,
        dimensions: { ...productData.dimensions, quantity: defaultDimension },
        discount: productData.product.discount,
        basicUnitPrice: priceForHumans(productData.price),
        unitPrice: priceForHumans(productData.discountedPrice)
      },
      selection: {
        mechanism: productData.initialMechanism,
        material: productData.initialMaterial,
        group: productData.groups.find(
          (group) => group.id === productData.materials[0].group
        ),
        frame: productData.frames[0],
        background: productData.backgrounds[0],
        width: productData.dimensions.width.default,
        height: productData.dimensions.height.default,
        quantity: defaultDimension.default
      },
      agent: agentDataMapper(this.props.assignedAgent || currentAgentData)
    })
    this.setInstallationCost(this.state.data.product, this.state.agent)
  }

  onImagesReady = () => {
    this.setState({ isLoading: false })
  }

  refreshPrices(selection) {
    const params = [
      `width=${selection.newSelection.width}`,
      `height=${selection.newSelection.height}`,
      `material_id=${selection.materialId || ''}`,
      `system_id=${selection.systemId || ''}`,
      `selected_material_id=${selection.newSelection.material.id}`,
      `selected_system_id=${selection.newSelection.mechanism.id}`
    ].join('&')

    fetch(`${this.props.productPath}?${params}`, {
      headers: { Accept: 'application/json' },
      credentials: 'same-origin'
    })
      .then((r) => r.json())
      .then(({ price, discountedPrice, materials, mechanisms }) => {
        const dataModifier = { mechanisms, materials }

        if (selection.materialId) {
          delete dataModifier.materials
        }
        if (selection.systemId) {
          delete dataModifier.mechanisms
        }

        if (price && discountedPrice) {
          dataModifier.basicUnitPrice = priceForHumans(price)
          dataModifier.unitPrice = priceForHumans(discountedPrice)
          this.setState({
            selection: selection.newSelection,
            isVariantInvalid: false,
            data: { ...this.state.data, ...dataModifier }
          })
        } else {
          this.setState({
            selection: selection.newSelection,
            isVariantInvalid: true,
            data: { ...this.state.data, ...dataModifier }
          })
        }
      })
  }

  changeItemSelection = (kind, id) => {
    const { data } = this.state
    const dataPart = data[`${kind}s`]
    const newSelection = { ...this.state.selection }

    if (dataPart) {
      Object.assign(newSelection, { [kind]: findInArray(dataPart, id) })

      if (kind === this.stages.group.toLowerCase()) {
        const materialKey = this.stages.material.toLowerCase()
        newSelection[materialKey] = data[`${materialKey}s`].find(
          (material) => material.group === id
        )
      }
    }

    if (variantPriceModifiers.indexOf(kind) >= 0) {
      let selectionId = {
        systemId: newSelection.mechanism.id,
        materialId: newSelection.material.id
      }

      if (kind === 'material' || kind === 'group') {
        delete selectionId.systemId
      }
      if (kind === 'mechanism') {
        delete selectionId.materialId
      }

      let sanitizedParams = Object.assign(
        { newSelection: newSelection },
        { width: newSelection.width, height: newSelection.height },
        selectionId
      )

      this.refreshPrices(sanitizedParams)
    } else {
      this.setState({ selection: { ...newSelection } })
    }
  }

  changeDimension = (dimension, val) => {
    const newSelection = { ...this.state.selection, [dimension]: val }
    if (variantPriceModifiers.indexOf(dimension) >= 0) {
      this.refreshPrices({ newSelection: newSelection })
    } else {
      this.setState({ selection: newSelection })
    }
  }

  changeStage = (stage) => {
    this.setState({ stage })
  }

  changeBackground = (backward = false, index = null) => {
    const { backgrounds } = this.state.data
    const currentIndex =
      index !== null
        ? index
        : backgrounds.indexOf(this.state.selection.background)
    const dataLength = backgrounds.length

    let newIndex = currentIndex

    if (index !== null) {
    } else if (backward) {
      newIndex = currentIndex > 0 ? currentIndex - 1 : dataLength - 1
    } else {
      newIndex = currentIndex < dataLength - 1 ? currentIndex + 1 : 0
    }

    this.setState({
      selection: { ...this.state.selection, background: backgrounds[newIndex] }
    })
  }

  configuratorOpen = (stage = null) => {
    if (stage && this.stages.hasOwnProperty(stage.toLowerCase())) {
      this.changeStage(stage)
    }
    this.setState({ isOpen: true })
  }

  configuratorClose = () => {
    this.setState({ isOpen: false })
  }

  agentOpen = () => {
    this.setState({ isAgentOpen: true })
  }

  agentClose = () => {
    this.setState({ isAgentOpen: false })
  }

  callAgentUpdate = (agent) => {
    $.ajax({
      type: 'POST',
      url: '/api/project_agent',
      data: { agent_id: agent ? agent.id : null },
      success: () => {
        this.setState({ agent })
        this.setInstallationCost(this.state.data.product, this.state.agent)
      }
    })
  }

  setInstallationCost = (product, agent) => {
    let product_id = product ? product.id : null
    let agent_id = agent ? agent.id : null

    $.ajax({
      url:
        '/api/project_product?product_id=' +
        product_id +
        '&agent_id=' +
        agent_id,
      type: 'get',
      dataType: 'json',
      processData: false,
      success: (data) => {
        this.setState({ installationCost: data })
      }
    })
  }

  notificationOpen = () => {
    let $this = this

    $this.setState({ isNotificationOpen: false })
    clearTimeout(timer.value)
    setTimeout(function () {
      $this.setState({ isNotificationOpen: true })

      timer.value = setTimeout(function () {
        $this.setState({ isNotificationOpen: false })
      }, 5000)
    }, 200)
  }

  renderAgentModal = () => (
    <div className='product-agent'>
      <div className='product-agent__main'>
        {
          <ProductAgent
            selectedAgentId={this.state.agent ? this.state.agent.id : null}
            updateAgent={this.callAgentUpdate}
            initialAgent={this.state.agent}
            initiallyOpen={true}
          />
        }
      </div>

      <div className='product-agent__footer'>
        <button
          className='button product-agent__action'
          onClick={this.agentClose}
        >{`Zakończ wybór sprzedawcy`}</button>
      </div>
    </div>
  )

  finalize = () => {
    const selectionData = {
      product_id: this.props.id,
      mechanism_id: this.state.selection.mechanism.id,
      material_id: this.state.selection.material.id,
      width: this.state.selection.width,
      height: this.state.selection.height,
      quantity: this.state.selection.quantity
    }

    $.ajax({
      type: 'POST',
      url: '/api/project_items',
      data: selectionData,
      success: () => {
        window.dispatchEvent(new CustomEvent('cartShouldUpdate'))
      }
    })
  }

  render() {
    const previewSetup = {
      selection: this.state.selection,
      frames: this.state.data.frames,
      backgrounds: this.state.data.backgrounds,
      overlayPosition: this.state.data.overlayPosition,
      onPickerItemClick: this.changeItemSelection,
      hidePicker:
        this.state.data.backgrounds.length === 1
          ? 'product-preview__arrow--hidden'
          : '',
      onBgNavClick: this.changeBackground,
      onImagesReady: this.onImagesReady,
      promotion: this.props.promotion,
      percentage_discount_sum: this.props.percentage_discount_sum
      /*product: this.productData.product.name*/
    }

    const configuratorSetup = {
      stages: this.stages,
      state: this.state,
      onCloseClick: this.configuratorClose,
      onStageClick: this.changeStage,
      onItemClick: this.changeItemSelection,
      configuratorClose: this.configuratorClose,
      changeStage: this.changeStage,
      colorGroupName: this.props.colorGroupName,
      colorName: this.props.colorName,
      productSystemName: this.props.productSystemName,
      previewSetup: {
        ...previewSetup,
        addedClassNames: 'product-preview--inner'
      }
    }

    const generateProductStepper = (property, additionalClass = '') => {
      const dimensionSetup = this.state.data.dimensions[property]
      const { product } = this.state.data
      const dimensionLabel = {
        width: 'Szerokość okna (cm)',
        height: 'Wysokość okna (cm)'
      }

      return (
        <Stepper
          additionalClassNames={`field--distinguished product-description__field product-description__field--spaced ${additionalClass}`}
          id={`product_${property}`}
          label={dimensionLabel[property]}
          help={
            property === 'width' && product.measuringInstructions !== '#'
              ? {
                  label: 'Jak mierzyć okno?',
                  callback: () => {
                    window.open(product.measuringInstructions)
                  }
                }
              : null
          }
          val={this.state.selection[property]}
          min={dimensionSetup.min}
          max={dimensionSetup.max}
          ac
          step={dimensionSetup.step}
          initialValue={dimensionSetup.default}
          onValueUpdate={(val) => this.changeDimension(property, val)}
        />
      )
    }

    const generateProductFeature = (property) => {
      const labels = {
        description: 'Opis',
        technical: 'Dane techniczne',
        measure: 'Jak mierzyć okno?',
        group: 'Grupa kolorów:',
        material: 'Kolor',
        mechanism: 'Kolor osprzętu',
        invalid_variant: 'Produkt nie wystepuje w wybranym wymiarze',
        assembly_cost: 'montaż'
      }

      return (
        <p
          className='product-description__feature'
          onClick={() => {
            this.configuratorOpen(this.stages[property])
          }}
        >
          {`${labels[property]}: `}
          <strong>
            {this.state.selection[property]
              ? this.state.selection[property].name
              : '-'}
          </strong>
        </p>
      )
    }

    const { product, discount, basicUnitPrice } = this.state.data

    if (!product) {
      return null
    }

    const generateAgentSelection = () => {
      // if (!this.props.assignedAgent) {
      //   return (
      //     <button
      //       className='product-description__secondary-action button button--small button--bordered'
      //       disabled={this.state.isVariantInvalid}
      //       onClick={this.agentOpen}
      //     >
      //       {this.state.agent ? 'Zmień sprzedawcę' : 'Oszacuj koszt montażu'}
      //     </button>
      //   )
      // }
    }

    const generateThumbnail = () => {
      return (
        <div className='product-thumbnail'>
          <ScrollbarsHorizontal autoHeight autoHeightMax='100%'>
            <ul className='product-thumbnail__item-list'>
              {this.state.data.backgrounds.map((background, key) => (
                <li
                  className={`product-thumbnail__item${
                    this.state.data.backgrounds.indexOf(
                      this.state.selection.background
                    ) === this.state.data.backgrounds.indexOf(background)
                      ? ' product-thumbnail__item--active'
                      : ''
                  }`}
                  onClick={() => {
                    this.changeBackground(
                      false,
                      this.state.data.backgrounds.indexOf(background)
                    )
                  }}
                  key={key}
                >
                  <div>
                    <img
                      width={88}
                      height={88}
                      src={background.path}
                      alt={background.name}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </ScrollbarsHorizontal>
        </div>
      )
    }
    return (
      <section
        className={`product-detail segment segment--sticked-to-top segment--split${
          this.state.isLoading ? ' product-detail--transparent' : ''
        }`}
      >
        <div className='segment__part'>
          <ProductPreview {...previewSetup} />

          {generateThumbnail()}
        </div>

        <div className='segment__part'>
          <div className='product-description'>
            <div className='product-description__primary'>
              <h1 className='product-description__field'>
                {this.state.displayedName
                  ? this.state.displayedName
                  : this.state.productType + ' ' + product.name}
              </h1>

              <div className='product-description__field product-description__field--spaced'>
                {generateProductFeature('material')}
                {generateProductFeature('mechanism')}
              </div>

              <button
                className='button button--small product-description__button product-description__button--separate'
                onClick={() => {
                  this.configuratorOpen()
                }}
              >
                {`Zmień kolor`}
              </button>

              {this.props.hasSample ? (
                <a
                  href={`${this.props.samplesPath}/?product=${this.props.id}`}
                  className='button button-ebony button--small product-description__button'
                >
                  {`Zamów próbki`}
                </a>
              ) : (
                ''
              )}
            </div>

            <div className='product-description__secondary'>
              <p className='product-description__price'>
                <span className='product-description__price-discount'>
                  {discount > 0 && !this.state.isVariantInvalid
                    ? basicUnitPrice + 'zł'
                    : ''}
                </span>

                <span className='product-description__price-number'>
                  {(this.state.isVariantInvalid
                    ? '- '
                    : priceForHumans(
                        this.state.data.unitPrice *
                          this.state.selection.quantity
                      )) + 'zł'}
                </span>

                <span className='product-description__price-annotation'>
                  {`w tym 23% VAT`}
                </span>

                <span className='product-description__price-annotation'>
                  {this.state.isVariantInvalid
                    ? 'Produkt nie wystepuje w wybranym wymiarze'
                    : this.state.agent
                    ? this.state.installationCost
                      ? '+ koszt montażu od ' +
                        this.state.installationCost *
                          this.state.selection.quantity +
                        'zł'
                      : '+ cena montażu ustalana indywidualnie'
                    : '+ koszt montażu'}
                </span>
              </p>

              {generateAgentSelection()}

              <div
                className={
                  this.state.isVariantInvalid
                    ? 'product-description__offscale active'
                    : 'product-description__offscale'
                }
              >
                <p className='product-description__size'>
                  <span>{`Wymiar poza dostępną skalą. Potrzebujesz niestandardowego rozmiaru?`}</span>
                </p>
                <a href='/darmowa-konsultacja'>
                  <button className='button button--small button-raspberry hidden-mobile'>{`umów konsultację`}</button>
                  <button className='button button--small button-raspberry hidden-desktop'>{`umów się`}</button>
                </a>
              </div>
            </div>

            <div className='product-description__tertiary'>
              {generateProductStepper('width')}
              {generateProductStepper('height')}
              {generateProductStepper(
                'quantity',
                'product-description__field--small'
              )}

              <button
                className='product-description__field--action button'
                disabled={this.state.isVariantInvalid}
                onClick={() => {
                  this.notificationOpen()
                  this.finalize()
                }}
              >
                Wyceń
              </button>
            </div>

            <p className='product-description__addition'>
              {product.shortDescription}
            </p>
          </div>
        </div>

        <ProductConfigurator {...configuratorSetup} />

        <div
          className={`loader loader--opaque ${
            this.state.isLoading && 'loader--active'
          }`}
        />

        <Modal
          isOpen={this.state.isAgentOpen}
          onCloseClick={this.agentClose}
          renderContent={this.renderAgentModal}
        />

        <Notification
          isOpen={this.state.isNotificationOpen}
          checkoutPath={this.props.checkoutPath}
          productName={product.name}
          productWidth={this.state.selection.width}
          productHeight={this.state.selection.height}
          productMaterialName={this.state.selection.material.name}
          productMaterialPath={this.state.selection.material.path}
          productMachanismName={this.state.selection.mechanism.name}
          productMachanismPath={this.state.selection.mechanism.path}
        />
      </section>
    )
  }
}

ProductDetail.propTypes = {
  id: PropTypes.number,
  productPath: PropTypes.string,
  checkoutPath: PropTypes.string,
  hasSample: PropTypes.bool,
  samplesPath: PropTypes.string,
  colorGroupName: PropTypes.string,
  colorName: PropTypes.string,
  productSystemName: PropTypes.string,
  productType: PropTypes.string,
  displayedName: PropTypes.string
}

export default ProductDetail
