import React from 'react';
import PropTypes from 'prop-types';

import agentDataMapper from './../helpers/mappers/agentDataMapper';
import customerDataMapper from './../helpers/mappers/customerDataMapper';

import CheckoutCart from './CheckoutCart';
import CheckoutCustomer from './CheckoutCustomer';
import CheckoutAgent from './CheckoutAgent';
import CheckoutSummary from './CheckoutSummary';
import CheckoutConfirmation from './CheckoutConfirmation';

import { CSSTransitionGroup } from 'react-transition-group';

class Checkout extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      steps: this.props.assignedAgent ? ['Projekt', 'Twoje dane', 'Podsumowanie'] : ['Projekt', 'Twoje dane', 'Sprzedawca', 'Podsumowanie'].map(s => ({name: s, completed: false})),
      currentStep: 0,
      userSignedIn: props.userSignedIn,
      projectItems: props.project.project_items.map(this.parseProjectItem),
      customerData: customerDataMapper(props.project.customer_data),
      agent: agentDataMapper(this.props.assignedAgent || props.project.agent),
      installationCost: null,
    };

    this.requiredCustomerFields = ['firstName', 'email', 'phoneNumber', 'address'];
  }

  setInstallationCost = (agent, project_items) => {
    let installationCost = 0;

    project_items.forEach(i => {
      i.variants.forEach(v => {
        installationCost += this.getInstallationCost(i, agent) * v.quantity;
      });
    });

    this.setState({ installationCost: installationCost ? parseFloat(installationCost) : null });
  }

  getInstallationCost = (product, agent) => {
    let product_id = product ? product.product_id : null;
    let agent_id = agent ? agent.id : null;
    var installationCost = 0;

    $.ajax({
      url: '/api/project_product?product_id=' + product_id + '&agent_id=' + agent_id,
      type: 'get',
      dataType: 'json',
      processData: false,
      async: false
    }).done(function(data){
      installationCost += data;
    });

    return installationCost;
  }

  parseProjectItem(item) {
    const parsedItem = { variants: [], ...item, item_id: parseInt(item.item_id) };

    const intValues = ['width', 'height', 'quantity', 'variant_id', 'id'];
    const floatValues = ['unit_price', 'total_price', 'quantity', 'variant_id'];

    parsedItem.variants.forEach(v => {
      intValues.forEach(function(key) {v[key] = parseInt(v[key]);});
      floatValues.forEach(function(key) {v[key] = parseFloat(v[key]);});
    });

    return parsedItem;
  }

  parseCustomerData(data) {
    return {
      firstName: data.first_name,
      surname: data.surname,
      email: data.email,
      phoneNumber: data.phone_number,
      address: data.address,
      message: data.message,
      coordinates: {
        lat: data.lat,
        lng: data.lng,
      }
    };
  }

  componentDidMount() {
    window.addEventListener('cartShouldUpdate', () => {this.refreshProject();});
    this.determineCompletedSteps();
    this.changeStep(this.state.steps.findIndex(s => !s.completed));
    this.setInstallationCost(this.state.agent, this.state.projectItems);
  }

  determineCompletedSteps = () => {
    const steps = [ ...this.state.steps ];
    if (!this.requiredCustomerFields.some(key => !this.state.customerData[key])) {
      steps[0].completed = true;
      steps[1].completed = true;
    }
    if (this.state.agent && !this.props.assignedAgent) {
      steps[2].completed = true;
    }
    if (this.state.projectId) {
      steps[4].completed = true;
    }
    this.setState({ steps });
  };

  isStepAvailable = (index) => (index <= this.state.steps.findIndex(s => !s.completed));

  changeStep(index) {
    if (this.isStepAvailable(index)) {
      this.setState({currentStep: index});
      this.setInstallationCost(this.state.agent, this.state.projectItems);
    }
  }

  getFinalizedStepState = () => {
    const { currentStep } = this.state;

    const newState = {
      ...this.state,
      currentStep: currentStep + 1
    };
    newState.steps[currentStep].completed = true;

    return newState;
  };

  navigateBack = () => {
    this.changeStep(this.state.currentStep - 1);
  };

  navigateHome() {
    document.location.href = '/';
  }

  refreshProject = () => {
    fetch('/api/project', { headers: { Accept: 'application/json' }, credentials: 'same-origin' })
      .then((r) => r.json())
      .then((data) => {
        this.setState({
          projectItems: data.project_items.map(this.parseProjectItem)
          /*agent: agentDataMapper(this.props.assignedAgent || this.props.project.agent)*/
        });
      });
  };

  callAgentUpdate = (agent, oldState = {}) => {
    $.ajax({
      type: 'POST',
      url: '/api/project_agent',
      data: { agent_id: (agent ? agent.id : null) },
      success: () => {this.setState({ ...oldState, agent });},
    });
  };

  callProjectFinalize = (oldState = {}) => {
    $.ajax({
      type: 'POST',
      url: '/api/project/update',
      data: { rules_accepted: '1' },
      success: ({ project_id }) => {this.setState({ ...oldState, projectId: project_id });},
    });
  };

  onCartFinalize = (projectItems) => {
    const newState = this.getFinalizedStepState();
    this.setState({
      ...newState,
      projectItems
    });

    this.setInstallationCost(this.state.agent, projectItems);
  };

  onCustomerFinalize = (customerData) => {
    const newState = this.getFinalizedStepState();
    newState.userSignedIn = customerData.userSignedIn;
    this.setState({
      ... newState,
      customerData: customerDataMapper(customerData)
    });
  };

  onAgentFinalize = (agent) => {
    const newState = this.getFinalizedStepState();
    if (agent !== this.state.agent) {
      this.callAgentUpdate(agent, newState);
    } else {
      this.setState({ ...newState });
    }

    this.setInstallationCost(agent, this.state.projectItems);
  };

  onSummaryFinalize = (rulesAccepted) => {
    if (this.props.assignedAgent) {
      this.callAgentUpdate(this.state.agent);
    }

    if (rulesAccepted) {
      const newState = this.getFinalizedStepState();

      this.callProjectFinalize(newState);
    }
  };

  renderHeader() {
    const getStepClasses = (step, i) => {
      const stepClasses = ['checkout-steps__step'];
      if (this.state.currentStep === i) {stepClasses.push('checkout-steps__step--active');}
      if (step.completed) {stepClasses.push('checkout-steps__step--checked');}
      if (this.isStepAvailable(i)) {stepClasses.push('checkout-steps__step--available');}
      return stepClasses;
    };

    if (this.state.currentStep === 4) {
      return null;
    }

    return (
      <div className='background'>
        <ol className='checkout-steps'>
          {this.state.steps.map((step, i) => (
            <li className={getStepClasses(step, i).join(' ')} key={i} onClick={() => {this.changeStep(i);}}>
              <p className='checkout-steps__step-title'>
                <span className='checkout-steps__step-counter'>{`0${i + 1}`}</span>

                <span>{step.name}</span>
              </p>
            </li>
          ))}
        </ol>
      </div>
    );
  }

  renderCheckoutWithoutAgent(transitionProperties, step) {
    const initialLocation = { address: this.state.customerData.address, coordinates: this.state.customerData.coordinates };

    return (
      <CSSTransitionGroup {...transitionProperties}>
        {step === 0 && <CheckoutCart
          projectItems={this.state.projectItems}
          installationCost={this.state.installationCost ? this.state.installationCost : null}
          onFinalize={this.onCartFinalize}
          onBack={this.navigateHome}
          termsOfUsePath={this.props.termsOfUsePath}
          setInstallationCost={this.setInstallationCost}
          initialAgent={this.state.agent}
        />}

        {step === 1 && <CheckoutCustomer
          customerData={this.state.customerData}
          requiredFields={this.requiredCustomerFields}
          onFinalize={this.onCustomerFinalize}
          onBack={this.navigateBack}
          userSignedIn={this.state.userSignedIn}
          userData={this.props.currentUserData}
          termsOfUsePath={this.props.termsOfUsePath}
          facebookRegistrationPath={this.props.facebookRegistrationPath}
          googleRegistrationPath={this.props.googleRegistrationPath}
        />}

        {step === 2 && <CheckoutAgent
          initialLocation={initialLocation}
          initialAgent={this.state.agent}
          selectedAgentId={this.state.agent ? this.state.agent.id : null}
          updateAgent={this.callAgentUpdate}
          onFinalize={this.onAgentFinalize}
          onBack={this.navigateBack}
          projectItems={this.state.projectItems}
          setInstallationCost={this.setInstallationCost}
          starIcon={this.props.starIcon}
        />}

        {step === 3 && <CheckoutSummary
          customerData={this.state.customerData}
          projectItems={this.state.projectItems}
          termsOfUsePath={this.props.termsOfUsePath}
          agent={this.state.agent}
          onFinalize={this.onSummaryFinalize}
          onBack={this.navigateBack}
          installationCost={this.state.installationCost ? this.state.installationCost : null}
        />}

        {step === 4 && <CheckoutConfirmation
          firmName={ this.state.agent ? this.state.agent.name : null }
          projectId={this.state.projectId}
          anwisPhoneNumber={this.props.anwisPhoneNumber}
          anwisEmail={this.props.anwisEmail}
          action={this.navigateHome} />}
      </CSSTransitionGroup>
    );
  }

  renderCheckoutWithAgent(transitionProperties, step) {
    return (
      <CSSTransitionGroup {...transitionProperties}>
        {step === 0 && <CheckoutCart
          projectItems={this.state.projectItems}
          installationCost={this.state.installationCost ? this.state.installationCost : null}
          onFinalize={this.onCartFinalize}
          onBack={this.navigateHome}
          setInstallationCost={this.setInstallationCost}
          initialAgent={this.state.agent}
        />}

        {step === 1 && <CheckoutCustomer
          customerData={this.state.customerData}
          requiredFields={this.requiredCustomerFields}
          assignedAgent={this.props.assignedAgent}
          onFinalize={this.onCustomerFinalize}
          onBack={this.navigateBack}
          userSignedIn={this.state.userSignedIn}
          userData={this.props.currentUserData}
          termsOfUsePath={this.props.termsOfUsePath}
          facebookRegistrationPath={this.props.facebookRegistrationPath}
          googleRegistrationPath={this.props.googleRegistrationPath}
        />}

        {step === 2 && <CheckoutSummary
          customerData={this.state.customerData}
          projectItems={this.state.projectItems}
          termsOfUsePath={this.props.termsOfUsePath}
          agent={this.state.agent}
          onFinalize={this.onSummaryFinalize}
          onBack={this.navigateBack}
          installationCost={this.state.installationCost ? this.state.installationCost : null}
        />}

        {step === 3 && <CheckoutConfirmation
          firmName={ this.state.agent ? this.state.agent.name : null }
          projectId={this.state.projectId}
          anwisPhoneNumber={this.props.anwisPhoneNumber}
          anwisEmail={this.props.anwisEmail}
          action={this.navigateHome} />}
      </CSSTransitionGroup>
    );
  }

  render() {
    const transitionProperties = {
      transitionName: 'fade',
      transitionEnterTimeout: 300,
      transitionLeaveTimeout: 0,
      component: 'main',
      className: 'checkout'
    };

    const { assignedAgent } = this.props;
    const { currentStep } = this.state;

    return (
      <div>
        {this.renderHeader()}

        { assignedAgent ? this.renderCheckoutWithAgent(transitionProperties, currentStep) : this.renderCheckoutWithoutAgent(transitionProperties, currentStep) }
      </div>
    );
  }
}

Checkout.propTypes = {
  project: PropTypes.object,
  userSignedIn: PropTypes.bool,
  starIcon: PropTypes.string
};

Checkout.defaultProps = {
  project: { project_items: [], customerData: null },
};

export default Checkout;
