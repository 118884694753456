import React from 'react';

import CheckoutAgent from './CheckoutAgent';

class ProductAgent extends CheckoutAgent {
  renderActions = () => null;

  generateHeader = () => (
    <div className='checkout-agent__header'>
      {this.generateAddressField()}

      <p className='checkout-agent__top product-agent__description'>
        {`Koszty montażu zależą od specjalisty, którego wybierzesz. Wpisz poniżej adres montażu - pozwoli on nam przydzielić Ci specjalistę w Twojej okolicy`}
      </p>
    </div>
  );

  propTypes = super.propTypes;
  defaultProps = super.defaultProps;
}

export default ProductAgent;
