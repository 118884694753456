export default function addressGeocoder(address, successCallback) {
  function geocodeAddress (a) {
    const geocoder = new google.maps.Geocoder();

    var bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(48.638092, 13.399244),
      new google.maps.LatLng(54.634647, 24.729369));

    geocoder.geocode({ address: a, bounds: bounds }, (results, status) => {
      if (status === 'OK') {
        const coords = results[0].geometry.location;

        if (typeof successCallback === 'function') {
          successCallback({ lat: coords.lat(), lng: coords.lng() });
        }
      }
    });
  }

  const geocodeCallback = () => {
    geocodeAddress(address);
  };

  if (window.mapsReady) {
    geocodeAddress(address);
  } else {
    window.addEventListener('mapsReady', geocodeCallback);
  }
}
