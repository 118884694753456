import React from 'react'
import PropTypes from 'prop-types'

class Stepper extends React.Component {
  constructor(props) {
    super(props)
    this.state = { val: props.initialValue || 0 }
  }

  emitValue(val) {
    if (this.props.onValueUpdate) {
      this.props.onValueUpdate(val)
    }
  }

  changeValue(val, verify = false) {
    this.setState({ val })

    if (verify) {
      this.verifyValue(val)
    }
  }

  verifyValue(unchecked = this.state.val) {
    const { min, max, step } = this.props
    const val = Math.max(Math.min(max, Math.ceil(unchecked / step) * step), min)

    this.setState({ val })
    this.emitValue(val)
  }

  onStepperClick(decrease) {
    this.changeValue(
      this.state.val + (decrease ? -1 : 1) * this.props.step,
      true
    )
  }

  /* 'jak mierzyć okno' w funkcji poniżej pod {this.props.label && ...} {this.props.help && <button type="button" className='field__help' onClick={this.props.help.callback}>{this.props.help.label}</button>}*/

  render() {
    return (
      <div
        className={`field field--stepper ${this.props.additionalClassNames}`}
      >
        {this.props.label && (
          <label
            className={`field__label  ${
              this.props.labelClass ? this.props.labelClass : ''
            }`}
            htmlFor={this.props.id}
          >
            {this.props.label}
          </label>
        )}
        {this.props.help && (
          <button
            type='button'
            className='field__help'
            onClick={this.props.help.callback}
          >
            {this.props.help.label}
          </button>
        )}
        <input
          className='field__input'
          id={this.props.id}
          type='number'
          value={this.state.val}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          onChange={(e) => {
            this.changeValue(e.target.value)
          }}
          onBlur={() => {
            this.verifyValue()
          }}
        />

        <button
          className='field__step'
          type='button'
          onClick={() => {
            this.onStepperClick(true)
          }}
        />

        <button
          className='field__step field__step--plus'
          type='button'
          onClick={() => {
            this.onStepperClick(false)
          }}
        />
      </div>
    )
  }
}

Stepper.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  help: PropTypes.object,
  initialValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onValueUpdate: PropTypes.func,
}

export default Stepper
