/* global document, fetch, clearTimeout, setTimeout */
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Select from 'react-select'
import debounce from './../helpers/debounce'

class SampleFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      products: this.props.products
    }
  }

  render() {
    const options = [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' }
    ]

    return <Select options={options} />
  }
}

SampleFilter.propTypes = {
  products: PropTypes.array
}

export default SampleFilter
