import React from 'react';
import PropTypes from 'prop-types';

import css from 'dom-css';
import { SpringSystem, MathUtil } from 'rebound';
import { Scrollbars } from 'react-custom-scrollbars';

class ScrollbarsHorizontal extends React.Component {
  componentDidMount() {
    this.springSystem = new SpringSystem();
    this.spring = this.springSystem.createSpring();
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate });
  }

  componentWillUnmount() {
    this.springSystem.deregisterSpring(this.spring);
    this.springSystem.removeAllListeners();
    this.springSystem = undefined;
    this.spring.destroy();
    this.spring = undefined;
  }

  scrollLeft(left) {
    const { scrollbars } = this.refs;
    const scrollLeft = scrollbars.getScrollLeft();
    const scrollWidth = scrollbars.getScrollWidth();
    const val = MathUtil.mapValueInRange(left, 0, scrollWidth, 0, scrollWidth);
    this.spring.setCurrentValue(scrollLeft).setAtRest();
    this.spring.setEndValue(val);
  }

  handleSpringUpdate = (spring) => {
    const { scrollbars } = this.refs;
    const val = spring.getCurrentValue();
    scrollbars.scrollLeft(val);
    this.handleUpdate(scrollbars.getValues());
  }

  handleUpdate = (values) => {
    const { shadowLeft, shadowRight, arrowLeft, arrowRight } = this.refs;
    const { scrollLeft, scrollWidth, clientWidth } = values;
    const threshold = 10;
    const leftOpacity = 1 / 30 * Math.min(scrollLeft - threshold, 30);
    const rightScrollLeft = scrollWidth - clientWidth;
    const rightOpacity = 1 / 30 * (rightScrollLeft - Math.max(scrollLeft + threshold, rightScrollLeft - 30));

    css(shadowLeft, { opacity: leftOpacity });
    css(shadowRight, { opacity: rightOpacity });

    if (arrowLeft) {
      css(arrowLeft, { opacity: leftOpacity });
    }
    if (arrowRight) {
      css(arrowRight, { opacity: rightOpacity });
    }
  }

  onArrowClick = (backwards = false) => {
    const current = this.refs.scrollbars.getScrollLeft();
    const interval = (backwards ? -1 : 1) * 200;

    this.scrollLeft(current + interval);
  }

  render() {
    const { style, ...props } = this.props;
    const containerStyle = {
      ...style,
      position: 'relative'
    };

    return (
      <div style={containerStyle}>
        <Scrollbars autoHide ref='scrollbars' onUpdate={this.handleUpdate} {...props}/>

        <div ref='shadowLeft' className='scrollbars__shadow scrollbars__shadow--left' />
        <div ref='arrowLeft' className='scrollbars__arrow hide-below-sm' onClick={() => {this.onArrowClick(true);}} />

        <div ref='shadowRight' className='scrollbars__shadow scrollbars__shadow--right' />
        <div ref='arrowRight' className='scrollbars__arrow scrollbars__arrow--next hide-below-sm' onClick={() => {this.onArrowClick(false);}} />
      </div>
    );
  }
}

ScrollbarsHorizontal.propTypes = {
  style: PropTypes.object,
};

export default ScrollbarsHorizontal;
