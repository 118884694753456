import React from 'react';
import PropTypes from 'prop-types';
import ProductPreview from './ProductPreview';
import ScrollbarsHorizontal from './ScrollbarsHorizontal';

class ProductConfigurator extends React.Component {
  transparencies = { '0': 'Transparentny', '1': 'Półprzezroczysty', '2': 'Zaciemniający' };

  render() {
    const generateFolderHeading = (stage, label = '', index) => {
      return (
        <li
          className={`folder__heading${ this.props.state.stage === stage ? ' folder__heading--active' : ''}`}
          onClick={() => {this.props.onStageClick(stage);}}
        >
          <span className='folder__heading-text'>
            {index && <span className='folder__heading-index'>{index}</span>}
            {label}
          </span>
        </li>
      );
    };

    const generateItems = (stage, filteredItems) => {
      const stageKey = stage.toLowerCase();
      const items = filteredItems ? filteredItems : this.props.state.data[`${stageKey}s`];

      return (
        items.map((item, key) => (
          <li
            className='product-configurator__item'
            onClick={() => {this.props.onItemClick(stageKey, item.id);}}
            key={key}
          >
            <div
              className={`sample ${this.props.state.selection[stageKey] && item.id === this.props.state.selection[stageKey].id ? ' sample--active' : ''}`}>
              <img className='sample__content' src={item.sample} alt={item.name} />
            </div>

            <h4 className='product-configurator__item-text'>{item.name}</h4>

          {stage !== this.props.stages.group && <p className='product-configurator__item-text'>
              {(item.price && item.price > 0 ? priceForHumans(item.price) + 'zł' : '')}
            </p>}
          </li>
        ))
      );
    };

    const generateGroupedItems = (stage, groupBy, inline = false) => {
      const stageKey = stage.toLowerCase();

      let items = this.props.state.data[`${stageKey}s`];
      if (this.props.state.selection.group && (stage === this.props.stages.material)) {
        items = items.filter(item => item.group === this.props.state.selection.group.id);
      }

      const groupedItems = !groupBy ? items : items.reduce((acc, item) => {
        const kind = item[groupBy];
        if (acc.hasOwnProperty(kind)) {
          acc[kind].items.push(item);
        } else {
          acc[kind] = { label: this.transparencies[kind], items: [item] };
        }
        return acc;
      }, {});

      if (inline) {
        return (
          <ul className='product-configurator__group-list product-configurator__group-list--inline'>
            {Object.keys(groupedItems).map(key => (
              <li className='product-configurator__group' key={key}>
                <h4 className='product-configurator__group-title'>{groupedItems[key].label}</h4>

                <ul className='product-configurator__item-list product-configurator__item-list--single-line'>
                  {generateItems(stage, groupedItems[key].items)}
                </ul>
              </li>
            ))}
          </ul>
        );
      }

      return Object.keys(groupedItems).map(key => (
        <section className='product-configurator__group' key={key}>
          <h3 className='product-configurator__group-title'>{groupedItems[key].label}</h3>

          <ScrollbarsHorizontal autoHeight autoHeightMax='100%'>
            <ul className='product-configurator__item-list product-configurator__item-list--single-line'>
              {generateItems(stage, groupedItems[key].items)}
            </ul>
          </ScrollbarsHorizontal>
        </section>
      ));
    };

    const generateFolderContent = (stage, groupBy) => {
      const isActive = this.props.state.stage === stage;
      const classNames = ['folder__text', 'configurator__tab'];

      if (isActive) {
        classNames.push('folder__text--active');
      }

      if (groupBy) {
        const inlineable = (stage === this.props.stages.material);

        return (
          <li className={classNames.join(' ')}>
            {inlineable && <ul className='product-configurator__scroll-wrapper hide-sm'>
              <ScrollbarsHorizontal autoHeight autoHeightMax='100%'>
                {generateGroupedItems(stage, groupBy, true)}
              </ScrollbarsHorizontal>
            </ul>}

            <section className={`product-configurator__group-list product-configurator__group-list--vertical ${inlineable ? 'hide-below-sm' : ''}`}>
              {generateGroupedItems(stage, groupBy)}
            </section>
          </li>
        );
      } else {
        const itemsList = groupBy ? null : generateItems(stage, stage.items);

        return (
          <li className={classNames.join(' ')}>
            <div className='product-configurator__scroll-wrapper hide-sm'>
              <ScrollbarsHorizontal autoHeight autoHeightMax='100%'>
                <ul className='product-configurator__item-list product-configurator__item-list--single-line'>
                  {itemsList}
                </ul>
              </ScrollbarsHorizontal>
            </div>

            <ul
              className='product-configurator__item-list product-configurator__item-list--multi-line hide-below-sm'>
              {itemsList}
            </ul>
          </li>
        );
      }
    };

    const generateFolderActions = stage => {
      const { stages } = this.props;
      const buttonSetup = (label, callback) => ({ label, callback });
      const setup = {
        prev: buttonSetup('Anuluj', () => {
          this.props.configuratorClose();
        }),
        next: buttonSetup('Potwierdź', () => {
          this.props.configuratorClose();
        })
      };

      switch (stage) {
        case stages.group:
          setup.next = buttonSetup('Dalej', () => {
            this.props.changeStage(stages.material);
          });
          break;
        case stages.material:
          setup.prev = buttonSetup('Wróć', () => {
            this.props.changeStage(stages.group);
          });
          setup.next = buttonSetup('Dalej', () => {
            this.props.changeStage(stages.mechanism);
          });
          break;
        case stages.mechanism:
          setup.prev = buttonSetup('Wróć', () => {
            this.props.changeStage(stages.material);
          });
      }

      const generateButton = (direction) => (
        <button
          type='button'
          className={`button${(direction === 'prev') ? ' button--flat' : ''}`}
          onClick={setup[direction].callback}
        >
          {setup[direction].label}
        </button>
      );

      return (
        <div className='product-configurator__actions'>
          {generateButton('prev')}
          {generateButton('next')}
        </div>
      );
    };

    return (
      <section className={`product-configurator${this.props.state.isOpen ? ' product-configurator--active' : ''}`}>
        <div className='folder product-configurator__container'>
          <ProductPreview {...this.props.previewSetup} />

          <ul className='folder__header product-configurator__folder-header'>
            {generateFolderHeading(this.props.stages.group, this.props.colorGroupName, '01')}
            {generateFolderHeading(this.props.stages.material, this.props.colorName, '02')}
            {generateFolderHeading(this.props.stages.mechanism, this.props.productSystemName, '03')}
          </ul>

          <ul className='folder__content product-configurator__content'>
            {generateFolderContent(this.props.stages.group)}
            {generateFolderContent(this.props.stages.material, 'transparency_level')}
            {generateFolderContent(this.props.stages.mechanism)}
          </ul>

          {generateFolderActions(this.props.state.stage)}
        </div>

        <button onClick={this.props.onCloseClick} className='close close--segment close--condensed-xs' />
      </section>
    );
  }
}

ProductConfigurator.propTypes = {
  state: PropTypes.object,
  stages: PropTypes.object,
  onCloseClick: PropTypes.func,
  onStageClick: PropTypes.func,
  onItemClick: PropTypes.func,
  colorGroupName: PropTypes.string,
  colorName: PropTypes.string,
  productSystemName: PropTypes.string
};

export default ProductConfigurator;
