import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

class Notification extends React.Component {
  render() {

    return (
      <section className={`notification notification--contained${this.props.isOpen ? ' notification--open' : ''}`}>
        <div className="notification__timeline"></div>
        <p className="notification__header">
          {`Do wyceny dodano:`}
        </p>
        <ul className="notification__products">
          <li className="notification__product-overview">
            <div className="product-overview">
              <div className="product-overview__header">
                <figure className="product-overview__preview">
                  <img src={this.props.productMaterialPath} alt={this.props.productMaterialName} className="product-overview__image"/>

                  <img src={this.props.productMachanismPath} alt={this.props.productMachanismName} className="product-overview__image"/>
                </figure>
              </div>
              <div className="product-overview__content">
                <div className="product-overview__description">
                  <a className="product-overview__title">{this.props.productName}</a>
                  <p className="product-overview__text">Kolor: {this.props.productMaterialName}</p>
                  <p className="product-overview__text">Kolor osprzętu: {this.props.productMachanismName}</p>
                  <p className="product-overview__text">Rozmiar: {this.props.productWidth} x {this.props.productHeight} cm</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div className="notification__footer">
          <div className="notification__actions">
            <Button
              href={this.props.checkoutPath}
              text={`wyślij zapytanie`}
              classNames='button notification__action--primary'
            />
          </div>
        </div>
      </section>
    );
  }
}

Notification.propTypes = {
  /*renderContent: PropTypes.func,
  overlayTransparent: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func*/
  productName: PropTypes.string,
  productWidth: PropTypes.number,
  productHeight: PropTypes.number,
  productMaterialName: PropTypes.string,
  productMaterialPath: PropTypes.string,
  productMachanismName: PropTypes.string,
  productMachanismPath: PropTypes.string,
  checkoutPath: PropTypes.string,
  isOpen: PropTypes.bool
};

Notification.defaultProps = {
  isOpen: false
};

export default Notification;
