import css from 'dom-css';
import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

class ScrollbarsInset extends React.Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0
    };
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleUpdate(values) {
    const { shadowTop, shadowBottom } = this.refs;
    const { scrollTop, scrollHeight, clientHeight } = values;
    const shadowTopOpacity = 1 / 30 * Math.min(scrollTop, 30);
    const bottomScrollTop = scrollHeight - clientHeight;
    const shadowBottomOpacity = 1 / 30 * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 30));
    css(shadowTop, { opacity: shadowTopOpacity });
    css(shadowBottom, { opacity: shadowBottomOpacity });
  }

  render() {
    const { style, ...props } = this.props;
    const containerStyle = {
      ...style,
      position: 'relative'
    };

    return (
      <div style={containerStyle}>
        <Scrollbars autoHide ref="scrollbars" onUpdate={this.handleUpdate} {...props}/>

        <div ref="shadowTop" className='scrollbars__shadow scrollbars__shadow--top' />

        <div ref="shadowBottom" className='scrollbars__shadow scrollbars__shadow--bottom'/>
      </div>
    );
  }
}

ScrollbarsInset.propTypes = {
  style: PropTypes.object,
};

export default ScrollbarsInset;
