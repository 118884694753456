import React from 'react';
import PropTypes from 'prop-types';

import { setCookie, getCookie } from './../helpers/cookieService';
import agentDataMapper from '../helpers/mappers/agentDataMapper';
import coordinatesDistance from '../helpers/coordinatesDistance';

import ScrollbarsInset from './ScrollbarsInset';
import Agent from './Agent';
import Field from './Field';

class AgentsList extends React.Component {
  locationCookieSetup = {
    name: 'userLocation',
    days: 30,
  };

  locationDefault = {
    address: '',
    coordinates: { lat: '52.069488', lng: '19.480311' },
  };

  googleMap = null;
  googleMapMarkers = [];

  state = {
    address: '',
    coordinates: { lat: '', lng: ''},
    agents: [],
  };

  agentsLimit =  getCookie(this.locationCookieSetup.name) ? 6 : '';

  componentDidMount() {
    document.querySelectorAll('.js_folder').forEach(folder => (new Folder(folder).init()));

    const locationCookie = getCookie(this.locationCookieSetup.name) || this.locationDefault;

    if (locationCookie && locationCookie.coordinates.lat && locationCookie.coordinates.lng) {
      locationCookie.coordinates.lat = parseFloat(locationCookie.coordinates.lat);
      locationCookie.coordinates.lng = parseFloat(locationCookie.coordinates.lng);
      const { address, coordinates } = locationCookie;

      this.setState({
        address,
        coordinates
      }, () => {
        this.refreshAgentsData(coordinates);

        if (window.mapsReady) {
          this.initMap();
        } else {
          window.addEventListener('mapsReady', this.initMap);
        }
      });
    }
  }

  setCenter = () => {
    if (this.googleMap) {
      const { lat, lng } = this.state.coordinates;
      if (lat && lng) {
        this.googleMap.google_map.setCenter({lat: lat, lng: lng});
        if(getCookie(this.locationCookieSetup.name)){
          this.googleMap.google_map.setZoom(11);
        }else{
          this.googleMap.google_map.setZoom(6);
        }
      }
    }
  }

  generateMarkersSetup = () => {
    const { lat, lng } = this.state.coordinates;
    const markersSetup = [];
    const address = this.state.address.split(",");

    if (lat && lng && getCookie(this.locationCookieSetup.name)) {
      markersSetup.push({ icon: this.props.markerIconSpecial, position: { lat, lng }, name: 'Jesteś Tutaj', address: address[0], address2: address[1], phoneNumber: '' });
    }

    return markersSetup.concat(this.state.agents.map(agent => ({
      icon: this.props.markerIcon,
      position: { lat: agent.lat, lng: agent.lng },
      name: agent.name,
      address: agent.address,
      address2: agent.address2,
      phoneNumber: agent.phoneNumber,
      path: this.props.agentPath+agent.slug
    })));
  }

  initMap = () => {
    this.googleMap = new GoogleMap(this.refs.map);
    this.googleMap.init();
    this.refreshMapMarkers();
    this.setCenter();
  }

  refreshMapMarkers = () => {
    if(this.googleMap) {
      const markersSetup = this.generateMarkersSetup();

      this.googleMapMarkers.forEach(marker => marker.setMap(null));
      this.googleMapMarkers = [];

      this.googleMapMarkers = markersSetup.map(setup => {

        var map = this.googleMap.google_map;

        var marker =  new google.maps.Marker({
          ...setup,
          map: map,
          clickable: true
        });

        var content = '';

        if (marker.phoneNumber) {
          content = '<h3 class="agent__title agent__title--small agent__title--left"><a href="' + marker.path + '">' + marker.name + ' <span>&rarr;</span></a></h3><p class="agent__text"><br />' + marker.address + '</p><p class="agent__text">' + marker.address2 + '</p><a class="agent__text" href="tel:'+ marker.phoneNumber +'">'+ marker.phoneNumber +'</a>';
        } else {
          content = '<h3 class="agent__title agent__title--small agent__title--left">' + marker.name + '</h3><p class="agent__text"><br />' + marker.address + '</p><p class="agent__text">' + marker.address2 + '</p>';
        }


        var infowindow = new google.maps.InfoWindow({
            content: content
        });

        google.maps.event.addListener(marker, 'click', function() {
          infowindow.open(map,marker);
        });

        return marker;
      });
    }
  };

  refreshAgentsData = ({ lat, lng }) => {
    const params = `lat=${lat};lng=${lng};limit=${this.agentsLimit}`;

    fetch(`/api/agents?${params}`, { headers: { Accept: 'application/json' }, credentials: 'same-origin' })
      .then(r => r.json())
      .then(s => {
        const agents = s.map(agentDataMapper);
        const initial = this.props.initialAgent;

        if(initial && getCookie(this.locationCookieSetup.name)) {
          initial.distance = coordinatesDistance(initial.lat, initial.lng, lat, lng).toFixed(2);
        }

        this.setState({
          agents: (initial ? [initial, ...agents.filter(agent => agent.id !== initial.id)] : agents)
        }, () => {
          this.refreshMapMarkers();
          this.setCenter();
        });
      });
  };

  onAddressChange = (filedName, value, coordinates) => {
    if (coordinates) {
      this.setState({
        address: value,
        coordinates
      }, () => {
        this.setLocationCookie();
        this.refreshAgentsData(coordinates);
      });
    }
  }

  setLocationCookie() {
    const locationCookieData = {
      address: this.state.address,
      coordinates: this.state.coordinates,
    };

    setCookie(this.locationCookieSetup.name, JSON.stringify(locationCookieData), this.locationCookieSetup.days);
  }

  renderAddressField = () => {
    const cookieValue = getCookie(this.locationCookieSetup.name);

    return (
      <Field
        placeholder='Miasto, ulica, kod pocztowy'
        type='text'
        name='address'
        id='checkout-customer-address'
        autolocation='true'
        additionalClassNames='field--location js_autocomplete-address agent-list__field'
        additionalInputClassNames='js_autocomplete-input'
        handleChange={this.onAddressChange}
        defaultValue={cookieValue ? cookieValue.address : this.state.address}
      />
    );
  }

  renderAgents = (agents) => {
    const { initialAgent } = this.props;
    /*if (!this.state.agents.length) {
      return (<p>Podaj lokalizację, aby wyświetlić sprzedawców w twojej okolicy</p>);
    }*/

    return agents.map(agent => (
      <Agent key={agent.id} isSelected={initialAgent && initialAgent.id === agent.id} isOpen={true} starIcon={this.props.starIcon} {...agent} href={`${this.props.agentPath}${agent.slug}`}/>
    ));
  }

  render() {
    return (
      <section className='agent-list segment segment--last'>
        <div className='agent-list__header'>
          <h1 className='agent-list__title'>Znajdź najbliższego doradcę</h1>
          {this.renderAddressField()}
        </div>

        <div className='js_folder folder folder--split-lg'>
          <ul className='folder__header'>
            <li className='js_folder_heading folder__heading' data-refers='agent-list'>Sprzedawcy</li>
            <li className='js_folder_heading folder__heading' data-refers='agent-map'>Mapa</li>
          </ul>

          <ul className='folder__content agent-list__content'>
            <li className='js_folder_text folder__text agent-list__cards' id='agent-list'>
              <ScrollbarsInset style={{ height: '100%', margin: '0 -30px' }}>
                <ul className='agent-list__list'>
                  {this.renderAgents(this.state.agents)}
                </ul>
              </ScrollbarsInset>
            </li>

            <li className='js_folder_text folder__text' id='agent-map'>
              <div className='agent-list__map' ref="map" />
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

AgentsList.propTypes = {
  initialAgent: PropTypes.object,
  markerIcon: PropTypes.string,
  markerIconSpecial: PropTypes.string,
  agentPath: PropTypes.string,
  starIcon: PropTypes.string
};

export default AgentsList;
