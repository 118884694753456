import React from 'react';
import PropTypes from 'prop-types';

class CheckoutConfirmation extends React.Component {
  render() {
    const generateListWithAgent = () => {
      return(
        <ol className='checkout-confirmation__list'>
          <li className='checkout-confirmation__list-item' key='head'>{`Twój projekt trafi do specjalisty z firmy ${this.props.firmName}, który skontaktuje się z Tobą w celu omówienia złożonego projektu i ustalenia daty zrobienia pomiaru.`}</li>
          <li className='checkout-confirmation__list-item'>Dokładny pomiar Twoich okien pozwoli doprecyzować Twoje potrzeby i ustalić koszt oraz datę montażu.</li>
          <li className='checkout-confirmation__list-item'>Twój specjalista zajmie się montażem i rozliczeniem Twojego zamówienia.</li>
          <li className='checkout-confirmation__list-item'>Gotowe!</li>
        </ol>
      );
    };

    const generateListWithoutAgent = () => {
      return(
        <ol className='checkout-confirmation__list'>
          <li className='checkout-confirmation__list-item'>Nie wybrałeś/aś specjalisty. To oznacza, że zespół Modnie w Oknie znajdzie najlepszego wykonawcę dla Twojego projektu.</li>
          <li className='checkout-confirmation__list-item'>Wybrany przez nas specjalista skontaktuje się z Tobą w celu omówienia złożonego projektu i ustalenia daty zrobienia pomiaru.</li>
          <li className='checkout-confirmation__list-item'>Dokładny pomiar Twoich okien pozwoli doprecyzować Twoje potrzeby i ustalić koszt oraz datę montażu.</li>
          <li className='checkout-confirmation__list-item'>Specjalista zajmie się montażem i rozliczeniem Twojego zamówienia.</li>
          <li className='checkout-confirmation__list-item'>Gotowe!</li>
        </ol>
      );
    };

    return (
      <section className='checkout-confirmation'>
        <h1 className='checkout-confirmation__title'>{`Dziękujemy za wysłanie projektu!`}</h1>

        <p className='checkout-confirmation__text'>
          {` Numer Twojego projektu to: `}
          <span className='checkout-confirmation__number'>{this.props.projectId}</span>
        </p>

        <div className='checkout-confirmation__body'>
          <h2 className='checkout-confirmation__subtitle'>{`Co dalej?`}</h2>

          {this.props.firmName ? generateListWithAgent() : generateListWithoutAgent()}

          <div className='checkout-confirmation__description'>
            <p className='checkout-confirmation__text'>{`Jeśli nie otrzymasz potwierdzenia wysłania projektu emailem lub specjalista nie skontaktuje się z Tobą, daj nam znać i powołaj się na numer projektu.`}</p>

            <a href={`mailto:${this.props.anwisEmail}`} className='checkout-confirmation__details checkout-confirmation__details--spaced'>{this.props.anwisEmail}</a>

            <a href={`tel:${this.props.anwisPhoneNumber}`} className='checkout-confirmation__details'>{this.props.anwisPhoneNumber}</a>
          </div>
        </div>

        <div className='checkout-confirmation__actions'>
          <button className='button' onClick={this.props.action}>{`Wróć do strony głównej`}</button>
        </div>
      </section>
    );
  }
}

CheckoutConfirmation.propTypes = {
  projectId: PropTypes.string,
  action: PropTypes.func,
  firmName: PropTypes.string,
};

export default CheckoutConfirmation;
