export default function customerDataMapper(data) {
  if (!data) {
    return {
      firstName: '',
      surname: '',
      email: '',
      phoneNumber: '',
      address: '',
      message: '',
      coordinates: {
        lat: '',
        lng: '',
      }
    };
  }

  return {
    firstName: data.first_name,
    phoneNumber: data.phone_number,
    coordinates: { lat: data.lat, lng: data.lng },
    surname: data.surname,
    email: data.email,
    address: data.address,
    message: data.message,
  };
}
