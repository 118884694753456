export default function agentDataMapper(data, agent_id) {
  if (!data) {
    return null;
  }

  return {
    id: data.id,
    name: data.company_name,
    phoneNumber: data.phone_number,
    email: data.email,
    address: data.address,
    address2: data.address2,
    responseTime: data.response_time,
    priceRangeFrom: data.price_range_from === null ? null : parseFloat(data.price_range_from),
    description: data.description,
    distance: data.km_distance,
    lat: data.lat,
    lng: data.lng,
    contactHours: data.contact_hours,
    slug: data.slug,
    reviewSummary: data.review_summary ? parseFloat(data.review_summary).toFixed(1) : null
  };
}
