import React from 'react';
import PropTypes from 'prop-types';

import Variant from './Variant';

class ProductOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      variants: this.props.variants
    };
  }

  render() {
    const generateVariants = (variants) => {
      return variants.map(variant => {
        return (
          <Variant
            {...variant}
            item_id={this.props.item_id}
            updateVariant={this.props.updateVariant}
            removeVariant={this.props.removeVariant}
            restoreVariant={this.props.restoreVariant}
            key={variant.variant_id}
          />
        );
      });
    };

    return (
      <div className="product-overview">
        <div className="product-overview__header">
          <figure className="product-overview__preview">
            <img src={this.props.material_path} alt={this.props.material_name} className="product-overview__image"/>

            <img src={this.props.mechanism_path} alt={this.props.mechanism_name} className="product-overview__image"/>
          </figure>
        </div>

        <div className="product-overview__content">
          <div className="product-overview__description">
            <a href={this.props.path} className="product-overview__title">
              {this.props.product_type_name}: {this.props.product_name}
            </a>

            <p className="product-overview__text">Kolor: {this.props.material_name}</p>

            <p className="product-overview__text">Kolor osprzętu: {this.props.mechanism_name}</p>
          </div>

          <div className="product-overview__variants">
             {generateVariants(this.props.variants)}
          </div>
        </div>
      </div>
    );
  }
}

ProductOverview.propTypes = {
  item_id: PropTypes.number,
  variants: PropTypes.array,
  updateVariant: PropTypes.func,
  removeVariant: PropTypes.func,
  restoreVariant: PropTypes.func
};

ProductOverview.defaultProps = {
  variants: []
};

export default ProductOverview;
