import React from 'react'
import PropTypes from 'prop-types'

import Stepper from './Stepper'

class Variant extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRemoved: false,
    }
  }

  remove = () => {
    this.props.removeVariant(this.props.id, this.props.item_id)

    this.setState({
      isRemoved: true,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.refs.stepper && this.props.quantity !== nextProps.quantity) {
      this.refs.stepper.changeValue(nextProps.quantity)
    }
  }

  restore = () => {
    this.props.restoreVariant()

    this.setState({
      isRemoved: false,
    })
  }

  render() {
    const generateVariant = () => {
      return (
        <div className='variant'>
          <Stepper
            ref='stepper'
            additionalClassNames={'field--stepper-small variant__stepper'}
            id={`variant_${this.props.id}`}
            val={this.props.quantity}
            min={1}
            max={100}
            step={1}
            initialValue={this.props.quantity}
            onValueUpdate={(val) =>
              this.props.updateVariant(val, this.props.id, this.props.item_id)
            }
          />

          <p className='variant__details'>
            {this.props.width} x {this.props.height} cm
          </p>

          <p className='variant__details variant__details--distinct'>
            {this.props.discounted_total_price}zł
          </p>

          <button
            type='button'
            className='variant__button'
            onClick={this.remove}
          >
            <span className='variant__icon'></span>
          </button>
        </div>
      )
    }

    const variantIsRemoved = () => {
      return (
        <div className='variant variant--spaced' key={this.props.variant_id}>
          <p className='variant__details variant__details--warning'>
            Usunięto produkt
          </p>
          <button
            type='button'
            className='button button--flat'
            onClick={this.restore}
          >
            <span className='variant__text'>anuluj</span>
          </button>
        </div>
      )
    }

    return this.state.isRemoved ? variantIsRemoved() : generateVariant()
  }
}

Variant.propTypes = {
  variant_id: PropTypes.number,
  item_id: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  quantity: PropTypes.number,
  discounted_total_price: PropTypes.string,
  updateVariant: PropTypes.func,
  removeVariant: PropTypes.func,
  restoreVariant: PropTypes.func,
}

export default Variant
