export default function coordinatesDistance(lat1, lon1, lat2, lon2, unit = 'K') {
  const M = Math;
  const toRadians = (degrees) => M.PI * degrees / 180;
  const toDegrees = (radians) => 180 * radians / M.PI;

  const rlat1 = toRadians(lat1);
  const rlat2 = toRadians(lat2);
  const rtheta = toRadians(lon1 - lon2);

  const dist = toDegrees(M.acos(M.sin(rlat1) * M.sin(rlat2) + M.cos(rlat1) * M.cos(rlat2) * M.cos(rtheta))) * 60 * 1.1515;

  switch (unit) {
    case 'K':
      return dist * 1.609344;
    case 'N':
      return dist * 0.8684;
    case 'M':
      return dist;
  }
}
