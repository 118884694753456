import React from 'react'
import PropTypes from 'prop-types'

import Field from './Field'

class CheckoutCustomer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: {},
      firstName: '',
      surname: '',
      email: '',
      phoneNumber: '',
      address: '',
      message: '',
      coordinates: {
        lat: '',
        lng: '',
      },
      rulesAccepted: false,
      rulesEmailAccepted: false,
      ...this.props.customerData,
    }
  }

  toggleCheckbox = () => {
    this.setState({ rulesAccepted: !this.state.rulesAccepted })
  }

  toggleEmailCheckbox = () => {
    this.setState({ rulesEmailAccepted: !this.state.rulesEmailAccepted })
  }

  UNSAFE_componentWillMount() {
    if (this.props.userData) {
      ;[
        'firstName',
        'surname',
        'email',
        'phoneNumber',
        'address',
        'lat',
        'lng',
      ].forEach((element) => {
        if (!this.state[element]) {
          this.setState({ [element]: this.props.userData[element] })
        }
      })
    }
  }

  componentDidMount() {
    if (window.mapsReady) {
      this.onMapsReady()
    } else {
      window.addEventListener('mapsReady', this.onMapsReady)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mapsReady', this.onMapsReady)
  }

  onMapsReady() {
    initializeLocation()
  }

  requiredEmpty = () =>
    this.props.requiredFields.some((key) => !this.state[key])

  handleChange = (name, value, coordinates) => {
    const newState = { ...this.state }
    newState[name] = value

    this.setState(newState)

    if (coordinates) {
      this.setState({ coordinates })
    }
  }

  submitForm = (e) => {
    e.preventDefault()
    const data = {
      first_name: this.state.firstName,
      surname: this.state.surname,
      email: this.state.email,
      address: this.state.address,
      lat: this.state.coordinates.lat,
      lng: this.state.coordinates.lng,
      phone_number: this.state.phoneNumber,
      message: this.state.message,
      register: this.state.register,
      rules_accepted: this.state.rulesAccepted,
      password: this.state.password,
    }

    $.ajax({
      type: 'POST',
      url: '/api/customer_data',
      data,
      success: () => {
        data.userSignedIn = this.props.userSignedIn || this.state.register
        this.props.onFinalize(data)
      },
      error: (res) => {
        this.setState({ errors: res.responseJSON.errors })
      },
    })
  }

  renderRegisterForm = () => {
    if (this.props.userSignedIn) {
      return
    }

    const generateTooltip = (text) => {
      if (text) {
        return (
          <small className='form-error is-visible field__input '>{text}</small>
        )
      }
      return ''
    }

    return (
      <div>
        {/* <div className='field field--form field--checkbox'>
          <input
            className='field__input'
            id='checkout-customer-register'
            type='checkbox'
            name='register'
            onChange={(event) =>
              this.handleChange('register', event.target.checked)
            }
            ref='input'
          />

          <label htmlFor='checkout-customer-register' className='field__label'>
            <b>{`Chcę założyć konto`}</b>
          </label>
        </div> */}

        <div
          className={`checkout-customer__registration ${
            this.state.register
              ? 'checkout-customer__registration--visible'
              : 'checkout-customer__registration--hidden'
          } `}
        >
          <Field
            label={`Hasło*`}
            id='checkout-customer-password'
            type='password'
            name='password'
            handleChange={this.handleChange}
            defaultValue={this.state.password}
            error={this.state.errors.password}
          />

          <div>
            <div className='field'>
              <span className='field__label social_media__description'>
                {`Lub zarejestruj się używając konta społecznościowego`}
              </span>
            </div>

            <section className='checkout-customer__social-media-register-buttons'>
              <a
                className='button button__social-media--facebook'
                href={this.props.facebookRegistrationPath}
              >{`Facebook`}</a>
              <a
                className='button button__social-media--google'
                href={this.props.googleRegistrationPath}
              >{`Google`}</a>
            </section>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const missingRequired = this.requiredEmpty()

    return (
      <section className='checkout-customer'>
        <form
          className='flex-list checkout-customer__form'
          onSubmit={this.submitForm}
        >
          <div className='flex-list__item flex-list__item--apart'>
            <Field
              label={`Imię*`}
              placeholder={`Jan`}
              type='text'
              name='firstName'
              id='checkout-customer-first-name'
              handleChange={this.handleChange}
              defaultValue={this.state.firstName}
              error={this.state.errors.first_name}
            />

            <Field
              label={`Adres montażu*`}
              placeholder={`Ulica, miasto, kod pocztowy`}
              type='text'
              name='address'
              id='checkout-customer-address'
              autolocation='true'
              tooltip={`(pozwoli nam przydzielić Ci sprzedawcę w okolicy)`}
              additionalClassNames='field--location field--has-tooltip js_autocomplete-address'
              additionalInputClassNames='js_autocomplete-input'
              handleChange={this.handleChange}
              defaultValue={this.state.address}
              error={this.state.errors.address}
            />

            <Field
              placeholder={`Nazwisko*`}
              type='hidden'
              name='surname'
              id='checkout-customer-surname'
              handleChange={this.handleChange}
              defaultValue={this.state.surname}
              error={this.state.errors.surname}
            />

            <Field
              label={`Adres e-mail*`}
              placeholder={`adres@domena.pl`}
              type='email'
              name='email'
              id='checkout-customer-email'
              handleChange={this.handleChange}
              defaultValue={this.state.email}
              error={this.state.errors.email}
            />

            <Field
              label={`Numer telefonu*`}
              placeholder={`+48 000 000 000`}
              type='tel'
              name='phoneNumber'
              id='checkout-customer-phone'
              handleChange={this.handleChange}
              defaultValue={this.state.phoneNumber}
              error={this.state.errors.phone_number}
            />
          </div>

          <div className='flex-list__item flex-list__item--apart'>
            <Field
              label={`Dodatkowe informacje, które chcesz nam przekazać`}
              id='checkout-customer-message'
              name='message'
              handleChange={this.handleChange}
              defaultValue={this.state.message}
              error={this.state.errors.message}
            />

            <div className='field field--checkbox'>
              <input
                type='checkbox'
                id='checkout-customer-rules'
                className='field__input'
                defaultChecked={this.state.rulesAccepted}
                onClick={this.toggleCheckbox}
              />

              <label htmlFor='checkout-customer-rules' className='field__label'>
                {`Akceptuję`}{' '}
                <a
                  href={this.props.termsOfUsePath}
                  target='_blank'
                >{`Regulamin`}</a>{' '}
                {`i nie wnoszę zastrzeżeń*`}
              </label>
            </div>

            <div className='field field--checkbox'>
              <input
                type='checkbox'
                id='checkout-customer-rules-email'
                className='field__input'
                defaultChecked={this.state.rulesEmailAccepted}
                onClick={this.toggleEmailCheckbox}
              />

              <label
                htmlFor='checkout-customer-rules-email'
                className='field__label'
              >
                {`Wyrażam zgodę na kontakt za pośrednictwem e-mail przez`}
                <br />
                {`Modnie w Oknie i Partnerów*`}
              </label>
            </div>

            {this.renderRegisterForm()}
          </div>

          <div className='flex-list checkout-customer__actions'>
            <button
              type='button'
              className='button button--flat'
              onClick={this.props.onBack}
            >
              {`wróć`}
            </button>

            <button
              type='submit'
              className='button checkout-customer__button-accept'
              disabled={
                missingRequired ||
                !this.state.rulesAccepted ||
                !this.state.rulesEmailAccepted
              }
            >
              {this.props.assignedAgent
                ? 'Przejdź do podsumowania'
                : 'Przejdź do wyboru sprzedawcy'}
            </button>

            {missingRequired && (
              <span className='checkout-customer__accept-tooltip'>{`Uzupełnij wymagane pola (*)`}</span>
            )}
          </div>
        </form>
      </section>
    )
  }
}

CheckoutCustomer.propTypes = {
  customerData: PropTypes.object,
  requiredFields: PropTypes.array,
  onBack: PropTypes.func,
  onFinalize: PropTypes.func,
  userSignedIn: PropTypes.bool,
}

CheckoutCustomer.defaultProps = {
  customerData: {},
  requiredFields: [],
}

export default CheckoutCustomer
