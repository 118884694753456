import React from 'react';
import PropTypes from 'prop-types';

import CheckoutCart from './CheckoutCart';
import Button from './Button';

class NavCart extends CheckoutCart {
  componentDidMount() {
    window.dispatchEvent(new Event('navCartMounted'));

    window.addEventListener('cartShouldUpdate', () => {
      this.getData();
    });

    this.getData();
  }

  getData() {
    let installationCost = 0;

    fetch('/api/project', { headers: { Accept: 'application/json' }, credentials: 'same-origin' })
      .then((r) => r.json())
      .then((data) => {
        data.project_items.forEach(i => {
          i.variants.forEach(v => {
            v.unit_price = priceForHumans(v.discounted_unit_price);
            v.total_price = priceForHumans(v.discounted_total_price);
            installationCost += this.getInstallationCost(i, data.agent) * v.quantity;
          });
        });

        this.setState({
          projectItems: data.project_items,
          summary: this.calculateSummary(data.project_items),
          installationCost: installationCost ? parseFloat(installationCost) : null,
          agent: data.agent,
        });
      });
  }

  getInstallationCost = (product, agent) => {
    let product_id = product ? product.product_id : null;
    let agent_id = agent ? agent.id : null;
    var installationCost = 0;

    $.ajax({
      url: '/api/project_product?product_id=' + product_id + '&agent_id=' + agent_id,
      type: 'get',
      dataType: 'json',
      processData: false,
      async: false
    }).done(function(data){
      installationCost += data;
    });

    return installationCost;
  }

  countVariants = () => this.state.projectItems.reduce((acc, item) => {
    return acc + item.variants.reduce(((acc2, v) => acc2 + v.quantity), 0);
  }, 0);

  generateHeader = () => null;

  generateEmptyState = () => (
    <div className='checkout-cart__empty-cart'>
      <p className='checkout-cart__empty-cart-text'>{`Brak wycen w koszyku`}</p>
      <p className='checkout-cart__empty-cart-desc'>{`Koszyk wycen zawiera zestawienie przesłon, które Cię zainteresowały. Po poznaniu orientacyjnej ceny, będziesz mógł wybrać najbliżej zlokalizowanego od Ciebie Doradcę.`}</p>
      <p className='checkout-cart__empty-cart-desc'>{`Wycena pozwoli Doradcy na lepsze przygotowanie się do rozmowy z Tobą. `}<span>{`Wycena nie jest jednoznaczna z zamówieniem – nie zobowiązujesz się do żadnych opłat.`}</span></p>
    </div>
  );

  generateActions = () => (
    <div className='checkout-cart__actions'>
        <Button
          href={this.props.checkoutPath}
          text={`podsumuj koszyk wycen`}
          classNames='button checkout-cart__action--primary'
          disabled={this.isCartEmpty()}
        />
    </div>
  );

  generateCartButton = () => {
    let type = 'span';
    const props = { className: 'header__link header__link--has-dropdown header__cart-link' };
    const children = [
      (<span key="cart-link-text">{`Koszyk wycen`}</span>),
      (<span key="cart-link-counter" className={'header__counter' + (this.countVariants() > 0 ? ' flash' : '')}>{this.countVariants()}</span>)
    ];

    if (!this.isCartEmpty()) {
      type = 'a';
    }

    return React.createElement(type, props, children);
  }

  render() {
    return (
      <div className='js_nav-item'>
        <div className='header__cart-toggle'>
          <button type='button' className='header__cart-button js_nav-cart-button'>


            <svg width='20px' height='25px' viewBox='0 0 20 25' fill='#292a3d'>
              <path d='M17.9343225,6.25 L15.2165725,6.25 L15.2165725,5.76075 C15.2165725,2.582 12.6080725,0 9.5653225,0 L9.456925,0 C6.412925,0 3.804675,2.582 3.804675,5.76075 L3.804675,6.25 L1.086925,6.25 C0.489275,6.25 0,6.7666 0,7.3916 L0,23.3701005 C0,23.9677505 0.48925,24.4570255 1.086925,24.4570255 L17.934675,24.4570255 C18.532325,24.4570255 19.0216,23.9677755 19.0216,23.3701005 L19.0216,7.36435 C19.0216,7.092875 18.9132025,6.79405 18.695425,6.576275 C18.5046425,6.385745 18.2331475,6.25 17.9343225,6.25 Z M5.9783225,5.76075 C5.9783225,3.77735 7.6081975,2.17375 9.4558225,2.17375 L9.56422,2.17375 C11.412845,2.17375 13.04272,3.777275 13.04272,5.76075 L13.04272,6.25 L5.97822,6.25 L5.9783225,5.76075 Z M16.8475725,22.2832492 L2.1738225,22.2832492 L2.1738225,8.42375 L3.8046725,8.42375 L3.8046725,10.29875 C3.8046725,10.8964 4.2939225,11.385675 4.8915975,11.385675 C5.4892475,11.385675 5.9785225,10.896425 5.9785225,10.29875 L5.9785225,8.42375 L13.0440225,8.42375 L13.0440225,10.29875 C13.0440225,10.8964 13.5332725,11.385675 14.1309475,11.385675 C14.7286225,11.385675 15.2178725,10.896425 15.2178725,10.29875 L15.216895,8.42375 L16.847745,8.42375 L16.8475725,22.2832492 Z' />
            </svg>

            <span key="cart-link-counter" className={'header__counter mobile ' + (this.countVariants() > 0 ? ' flash' : '')}>{this.countVariants()}</span>
          </button>

          {this.generateCartButton()}
        </div>

        <div className='header__cart-dropdown js_nav-cart'>
          <div className='header__cart-body'>
            {super.render()}
          </div>
        </div>
      </div>
    );
  }
}

NavCart.propTypes = {
  projectItems: PropTypes.array,
  contained: PropTypes.bool,
  checkoutPath: PropTypes.string
};

NavCart.defaultProps = {
  projectItems: [],
  contained: true
};

export default NavCart;
